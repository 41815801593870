import React from "react"
import { NavLink } from "react-router-dom"
import right from '../../assets/img/about/right.png'
import wrong from '../../assets/img/about/wrong.png'

import member_img from '../../assets/img/about/member_img.png'

import * as Constant from '../Constant'

class Membership extends React.Component {

    constructor() {
        super();

        this.state = {
            membershipList: [],
            isPaid: false
        }
    }

    componentDidMount = () => {
        this.getPlan();
    }


    getPlan = async () => {

        await fetch(Constant.getMembership, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        membershipList: data.data
                    })

                    for (let index = 0; index < data.data.length; index++) {
                        const element = data.data[index].id;
                        this.getPaymentDetails(data.data[index].id)

                    }
                }
            })
    }

    getPaymentDetails = async (mid) => {

        var userId = localStorage.getItem('userId');

        await fetch(Constant.getPayment + '/' + userId + '/' + mid, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.data !== null) {
                    this.setState({
                        isPaid: true
                    })
                }
            })
    }

    onSubscribe = (id) => {
        var userId = localStorage.getItem('userId');
        if (userId === null) {
            var currentUrlPath = window.location.pathname;
            var pathSegments = currentUrlPath.split('/').slice(1);
            var formattedPath = pathSegments.join('/');
            localStorage.setItem('currentPath', formattedPath)
            window.location.href = Constant.BaseUrl + 'login'
        } else {
            window.location.href = Constant.BaseUrl + 'payment/' + id;
        }

    }


    render() {

        return (
            <>
                <div className="membership_banner">
                    <div className="row">
                        <div className="col-md-1 col-lg-1 col-xl-1 col-sm-12 col-xs-12 pt-5 pb-5"></div>
                        <div className="col-md-4 col-lg-4 col-xl-4 col-sm-12 col-xs-12 pt-5 pb-5">
                            <p className="pt-5 head_Text" style={{ fontSize: 45 }} >Choose what’s<br></br><br></br>
                                best for your<br></br><br></br>
                                child</p>
                            <p className="mt-4" style={{ textAlign: 'justify', color: '#fff', fontSize: 21 }}>We provide various online tutoring services such as Basic, Level up and Advanced. You can select the best services as per your needs.
                            </p>
                        </div>
                        <div className="col-md-1 col-lg-1 col-xl-1 col-sm-12 col-xs-12 pt-5 pb-5"></div>
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 "></div>
                    </div>
                </div>
                <p className="text-center mt-5 head_Text" style={{ color: '#A32272' }}>Membership plan</p>
                <div className="mt-5 mb-4 pink_bg" >
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">

                            <div className="row " style={{ border: '2px solid #777777', backgroundColor: '#fff', borderRadius: 20, marginTop: -447 }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="text-center member_head_text" style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>Features</th>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <th className="text-center member_head_text" style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>{item.package_name}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>
                                                        <p className="text-center price_text">${item.price}</p>
                                                        <p className="min_text text-center"> per 60 mins</p>
                                                    </td>
                                                ))
                                            }

                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Tailor-made program</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>
                                                        {
                                                            item.tailer_made_program === 'yes' ? <div className="right_div" >
                                                                <img src={right} alt="yes" />
                                                            </div> : <div className="right_div" >
                                                                <img src={wrong} alt="wrong" />
                                                            </div>
                                                        }
                                                    </td>
                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Fully customized schedule – <br></br>Changes allowed</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>
                                                        {
                                                            item.schedule_change === 'yes' ? <div className="right_div" >
                                                                <img src={right} alt="yes" />
                                                            </div> : <div className="right_div" >
                                                                <img src={wrong} alt="wrong" />
                                                            </div>
                                                        }
                                                    </td>
                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Access to resources</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="min_text text-center">{item.access_resource}</p></td>
                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Exceptional Support</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="min_text text-center">{item.support}</p></td>
                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Weekly Tutor-Parent/Teacher<br></br> interaction</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>
                                                        {
                                                            item.interaction === 'yes' ? <div className="right_div" >
                                                                <img src={right} alt="yes" />
                                                            </div> : <div className="right_div" >
                                                                <img src={wrong} alt="wrong" />
                                                            </div>
                                                        }
                                                    </td>
                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Free sample papers</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="min_text text-center"> {item.free_papers}</p></td>
                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Printable Worksheets</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>
                                                        {
                                                            item.worksheet === 'yes' ? <div className="right_div" >
                                                                <img src={right} alt="yes" />
                                                            </div> : <div className="right_div" >
                                                                <img src={wrong} alt="wrong" />
                                                            </div>
                                                        }
                                                    </td>
                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Pre and Post assessment</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>
                                                        {
                                                            item.assessment === 'yes' ? <div className="right_div" >
                                                                <img src={right} alt="yes" />
                                                            </div> : <div className="right_div" >
                                                                <img src={wrong} alt="wrong" />
                                                            </div>
                                                        }
                                                    </td>
                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Doubt clearing sessions</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>
                                                        {
                                                            item.clearing_session === 'yes' ? <div className="right_div" >
                                                                <img src={right} alt="yes" />
                                                            </div> : <div className="right_div" >
                                                                <img src={wrong} alt="wrong" />
                                                            </div>
                                                        }
                                                    </td>
                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Revision lessons for exams</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>
                                                        {
                                                            item.revision === 'yes' ? <div className="right_div" >
                                                                <img src={right} alt="yes" />
                                                            </div> : <div className="right_div" >
                                                                <img src={wrong} alt="wrong" />
                                                            </div>
                                                        }
                                                    </td>
                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Access to session recordings</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>
                                                        {
                                                            item.session_recording === 'yes' ? <div className="right_div" >
                                                                <img src={right} alt="yes" />
                                                            </div> : <div className="right_div" >
                                                                <img src={wrong} alt="wrong" />
                                                            </div>
                                                        }
                                                    </td>
                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}><p className="mb-3" >Extension material for challenging goals</p></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>
                                                        {
                                                            item.extension_material === 'yes' ? <div className="right_div" >
                                                                <img src={right} alt="yes" />
                                                            </div> : <div className="right_div" >
                                                                <img src={wrong} alt="wrong" />
                                                            </div>
                                                        }
                                                    </td>

                                                ))}
                                        </tr>
                                        <tr>
                                            <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}></td>
                                            {
                                                this.state.membershipList.map((item, i) => (
                                                    <td style={{ borderBottomWidth: 0, borderRightWidth: 1 }}>
                                                        <div className="mt-4 mb-3 mobile">
                                                            {
                                                                // userId === null ? <NavLink className="btn btn-info student-registration" to="/login" style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Subscribe</span></NavLink> : <a className="btn btn-info student-registration" href="javascript:void(0)" style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Subscribe</span></a>

                                                                this.state.isPaid === true ? '' : <a className="btn btn-info student-registration" href="javascript:void(0)" onClick={() => this.onSubscribe(item.id)} style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Subscribe</span></a>

                                                            }
                                                        </div>
                                                    </td>
                                                ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-6 mt-2" style={{ marginLeft: -12 }}>
                            <img src={member_img} />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4 mt-4">
                            <p className="head_Text" >Know your tutor<br></br><br></br>
                                for free</p>
                            <p className="mt-5" style={{ textAlign: 'justify', fontSize: 19, color: '#fff' }}>Before committing to any<br></br> membership plan, get to know <br></br>the tutors beforehand. Book your<br></br> free demo lesson today!</p>
                            <NavLink className="btn btn-info student-registration hover_white mt-5" to="/book-a-tutor" style={{ background: '#fff', color: '#A62374', borderRadius: 30 }}><span>Book free Lesson</span></NavLink>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>

            </>
        )
    }
}

export default Membership
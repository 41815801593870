import React from 'react'
import * as Constant from '../Constant'

export default class QuestionPaper extends React.Component {

    constructor() {
        super()

        this.state = {
            queTitle: '',
            QueList: [],
            rightAnswer: 0,
            student_answer: [],
            messages: []
        }
    }

    componentDidMount = () => {
        this.getQuestion();
    }

    storeAnswer = (id, value) => {
        let count = this.state.rightAnswer;

        this.state.QueList.forEach((item) => {
            if (item.id === id) {
                const StudentAnswer = value.replace(/\s/g, '').toLowerCase();
                const ActualAnswer = item.answer.replace(/\s/g, '').toLowerCase();

                if (item.answer === StudentAnswer) {
                    count++;
                    this.setState({
                        rightAnswer: count
                    })
                }
            }
        });

        this.setState((prev) => ({
            student_answer: {
                ...prev.student_answer,
                [id]: value
            }
        }));

    }


    getQuestion = async () => {

        var id = window.location.pathname.split('/')[2]

        await fetch(Constant.getQuestions + '/' + id, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        QueList: data.data
                    })
                    if (data.data.length !== 0) {
                        this.setState({
                            queTitle: data.data[0].paper_title
                        })
                    } else {
                        this.setState({
                            queTitle: null
                        })
                    }

                }
            })
    }

    submitAnswer = () => {
        const updatedMessages = {};
        this.state.QueList.forEach((item) => {
            const id = item.id;
            let message = "";
            const answer = this.state.student_answer[id];

            // Check if answer is defined
            if (answer === undefined || answer === null) {
                message = "Please Fill the Answer";
                console.log(`Question ${id}: Not filled`);
            } else {
                const normalizedCorrectAnswer = item.answer.replace(/[\s.]/g, '').toLowerCase(); // Remove spaces and dots, convert to lowercase
                const normalizedUserAnswer = answer.replace(/[\s.]/g, '').toLowerCase(); // Remove spaces and dots, convert to lowercase

                if (normalizedCorrectAnswer === normalizedUserAnswer) {
                    message = "Correct";
                    console.log(`Question ${id}: Correct`);
                } else {
                    message = "Wrong";
                    console.log(`Question ${id}: Wrong`);
                }
            }
            updatedMessages[id] = message;
        });
        this.setState({ messages: updatedMessages });
    }
    render() {
        return (
            <>
                <section className="privacy-policy">
                    <div className="container">
                        <div className="row m-5">
                            <div className="col-md-12">
                                <div className="card m-b-20" style={{ boxShadow: 'none', borderRadius: 0 }}>
                                    <div className='row' style={{ padding: 4 }}>
                                        <div className='col-md-12 bg-black' style={{ border: 'none' }}>
                                            <h2 className="largefont pt-3 pb-3" style={{ color: '#fff' }}>{this.state.queTitle}</h2>
                                        </div>
                                    </div>
                                    <div className="card-body row">
                                        <div className='col-md-1'></div>
                                        <div className='col-md-10'>
                                            {
                                                this.state.QueList.map((item, i) => (
                                                    <div>
                                                        <h3 className="mt-5">Question : {i + 1}</h3>
                                                        {
                                                            item.question !== null ? <div style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: item.question }}></div> : ''
                                                        }

                                                        {
                                                            item.question_type === 'mcq' ? <div key={i} className="radio-with-Icon" style={{ display: 'flex' }}>

                                                                <div >
                                                                    {
                                                                        item.option_1 === '' ? '' : <div className="radioOption-Item">
                                                                            <div className="custom-control custom-checkbox " style={{ marginRight: 10 }}>
                                                                                <input type="radio" className="custom-control-input " onClick={() => this.storeAnswer(item.id, 'option_1')} name={`option_${item.id}`} id={`option_1_${i}`} />
                                                                                <label htmlFor={`option_1_${i}`} style={{ color: '#A1467E' }} dangerouslySetInnerHTML={{ __html: item.option_1 }} className={`container center-class custom-control-label colorBlack ${this.state.student_answer[item.id] === 'option_1' ? 'checked' : ''}`}></label>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        item.option_2 === '' ? '' : <div className="radioOption-Item">
                                                                            <div className="custom-control custom-checkbox" style={{ marginRight: 10 }}>
                                                                                <input type="radio" className="custom-control-input " onClick={() => this.storeAnswer(item.id, 'option_2')} name={`option_${item.id}`} id={`option_2_${i}`} />
                                                                                <label className={`container center-class custom-control-label colorBlack ${this.state.student_answer[item.id] === 'option_2' ? 'checked' : ''}`} htmlFor={`option_2_${i}`} style={{ color: '#A1467E' }} dangerouslySetInnerHTML={{ __html: item.option_2 }}></label>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>

                                                                <div >
                                                                    {
                                                                        item.option_3 === '' ? '' : <div className="radioOption-Item">
                                                                            <div className="custom-control custom-checkbox" style={{ marginRight: 10 }}>
                                                                                <input type="radio" className="custom-control-input" onClick={() => this.storeAnswer(item.id, 'option_3')} name={`option_${item.id}`} id={`option_3_${i}`} />
                                                                                <label className={`container center-class custom-control-label colorBlack ${this.state.student_answer[item.id] === 'option_3' ? 'checked' : ''}`} htmlFor={`option_3_${i}`} style={{ color: '#A1467E' }} dangerouslySetInnerHTML={{ __html: item.option_3 }}></label>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {
                                                                        item.option_4 === '' ? '' : <div className="radioOption-Item">
                                                                            <div className="custom-control custom-checkbox" style={{ marginRight: 10 }}>
                                                                                <input type="radio" className="custom-control-input" onClick={() => this.storeAnswer(item.id, 'option_4')} name={`option_${item.id}`} id={`option_4_${i}`} />
                                                                                <label className={`container center-class custom-control-label colorBlack ${this.state.student_answer[item.id] === 'option_4' ? 'checked' : ''}`} htmlFor={`option_4_${i}`} style={{ color: '#A1467E' }} dangerouslySetInnerHTML={{ __html: item.option_4 }}></label>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div> : <div className="radio-with-Icon col-md-12 mt-2" >
                                                                <div className="form-group ">
                                                                    <input type="text" className="form-control" name="student_answer" style={{ width: '66%' }} onChange={(e) => this.storeAnswer(item.id, e.target.value)} placeholder="Type Your Answer" />
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.messages[item.id] == 'Wrong' ?
                                                                <span style={{ color: 'red', fontWeight: 'bold' }}>{this.state.messages[item.id]}</span>
                                                                :
                                                                <>
                                                                    {
                                                                        this.state.messages[item.id] == 'Correct' ? <span style={{ color: 'green', fontWeight: 'bold' }}>{this.state.messages[item.id]}</span> : <span style={{ color: 'red', fontWeight: 'bold' }}>{this.state.messages[item.id]}</span>
                                                                    }
                                                                </>
                                                        }
                                                    </div>
                                                ))
                                            }
                                            <div className="mt-3 " style={{ float: 'right' }}>
                                                <a className="btn btn-info student-registration" href="#" onClick={this.submitAnswer} style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Submit Answer</span></a>
                                            </div>
                                        </div>
                                        <div className='col-md-1'></div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}

import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import * as Constant from '../components/Constant'
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from 'react-google-recaptcha';
import LoadingOverlay from 'react-loading-overlay';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from "./Facebook";
import Google from '../assets/img/google.png'

const LoginPage = () => {
    const [previousPageUrl, setPreviousPageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role_id, setRoleId] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [captchaText, setCaptchaText] = useState('');
    const [userInput, setUserInput] = useState('');
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [captchError, setCaptchError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [num1, setNum1] = useState(Math.floor(Math.random() * 10) + 1);
    const [num2, setNum2] = useState(Math.floor(Math.random() * 10) + 1);
    const [answer, setAnswer] = useState('');
    const [isCorrect, setIsCorrect] = useState('');
    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState(null);

    const recaptchaRef = useRef();

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            console.log(codeResponse);
            setUser(codeResponse);
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
        if (user.access_token) {
            fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json'
                }
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data)
                    // Send user profile data to backend
                    fetch(Constant.googleLogin, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            console.log(data)
                            storeRoleId(data.data.role_id);
                            storeUserId(data.data.id);
                            setIsLoading(false);
                            setIsActive(false);
                            if (data.data.role_id === 3) {
                                var urlPath = localStorage.getItem('currentPath');
                                if (urlPath === null) {
                                    window.location.href = Constant.BaseUrl + "student-dashboard";
                                } else {
                                    window.location.href = Constant.BaseUrl + urlPath;
                                }
                            } else if (data.data.role_id === 4) {
                                window.location.href = Constant.BaseUrl + "tutor-dashboard";
                            } else {
                                window.location.href = Constant.BaseUrl;
                            }
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
        }
    }, [user]);

    const storeRoleId = async (roleId) => {
        try {
            localStorage.setItem("roleId", roleId);
            console.log(roleId);
        } catch (e) { }
    };

    const validate = () => {
        let emailError = "";
        let passwordError = "";

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!email) {
            emailError = "Please Enter Email Address.";
        } else {
            if (!reg.test(email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }

        if (!password) {
            passwordError = "Please Enter Password.";
        }

        if (emailError || passwordError) {
            setEmailError(emailError);
            setPasswordError(passwordError);
            return false;
        }

        return true;
    };

    const onSubmit = () => {
        if (validate()) {
            setEmailError(null);
            setPasswordError(null);
            onLogin();
        }
    };

    const storeUserId = async (userId) => {
        try {
            localStorage.setItem("userId", userId);
        } catch (e) { }
    };

    const storePlanId = async (planId) => {
        try {
            localStorage.setItem("planId", planId);
        } catch (e) { }
    };

    const onLogin = async () => {
        setIsLoading(true);
        setIsActive(true);

        const recaptchaValue = await recaptchaRef.current.executeAsync();
        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);
        formData.append("recaptchaToken", recaptchaValue);

        await fetch(Constant.LoginUrl, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    storeRoleId(data.data.role_id);
                    storeUserId(data.data.id);
                    if (data.data.plans) {
                        storePlanId(data.data.plans.id);
                    }
                    setEmail("");
                    setPassword("");
                    setIsLoading(false);
                    setIsActive(false);
                    setUserInput('');

                    if (data.data.role_id === 3) {
                        var urlPath = localStorage.getItem('currentPath');
                        if (urlPath === null) {
                            window.location.href = Constant.BaseUrl + "student-dashboard";
                        } else {
                            window.location.href = Constant.BaseUrl + urlPath;
                        }
                    } else if (data.data.role_id === 4) {
                        window.location.href = Constant.BaseUrl + "tutor-dashboard";
                    } else {
                        window.location.href = Constant.BaseUrl;
                    }
                } else {
                    setIsActive(false);
                    toast.error(data.message, {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    };

    const responseGoogle = (response) => {
        console.log(response);
        // Handle successful login
    };

    const responseErrorGoogle = (error) => {
        console.error(error);
        // Handle failed login
    };

    return (
        <LoadingOverlay
            active={isActive}
            spinner
            fadeSpeed={10}
            text='Logining...'
        >
            <section id="loginnn">
                <div className="container">
                    <div className="registration-form-outer">
                        <div className="container register">
                            <div className="row" style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <div className="col-md-12 register-right">
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div className="row tabheader" style={{ paddingBottom: '0%', marginTop: '50px' }}>
                                                <div className="col-md-12 text-center">
                                                    <ToastContainer />
                                                    <label type="button" className="logintabview">
                                                        <h2>Sign in</h2>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="mt-3" style={{ display: 'flex', justifyContent: "center" }}>
                                                <span onClick={() => login()} style={{
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    padding: 5,
                                                    borderRadius: 30,
                                                    borderColor: '#000',
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    cursor: 'pointer'
                                                }}>
                                                    <img src={Google} alt="Sign in with Google" title="Login with Google" style={{ height: 25, cursor: 'pointer' }} />
                                                    Sign in with Google
                                                </span>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group" style={{ marginTop: '25px', textAlign: "center" }}>
                                                    <h3>Or</h3>
                                                </div>
                                            </div>

                                            <div className="row register-form login_form" style={{paddingTop:15}}>
                                                <div className="col-md-3">
                                                    <h4>Email </h4>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            onChange={(event) => setEmail(event.target.value)}
                                                            className="form-control"
                                                            autoComplete="on"
                                                            placeholder="Email *"
                                                        />
                                                    </div>
                                                    {!!emailError && (
                                                        <text style={{ color: "red", marginLeft: "10px" }}>
                                                            {emailError}
                                                        </text>
                                                    )}
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>Password </h4>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            onChange={(event) => setPassword(event.target.value)}
                                                            className="form-control"
                                                            autoComplete="on"
                                                            placeholder="Password *"
                                                        />
                                                    </div>
                                                    {!!passwordError && (
                                                        <text style={{ color: "red", marginLeft: "10px" }}>
                                                            {passwordError}
                                                        </text>
                                                    )}
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>&nbsp;</h4>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="d-flex align-items-center">
                                                        <ReCAPTCHA
                                                            sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                                                            size="invisible"
                                                            ref={recaptchaRef}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>&nbsp;</h4>
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>&nbsp;</h4>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <NavLink to="/forgot-passowrd"><h5>Forgot Password?</h5></NavLink>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>&nbsp;</h4>
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>&nbsp;</h4>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="submit" className="btnRegister" onClick={() => onSubmit()} value="Login" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group" style={{ marginTop: '25px', textAlign: "center" }}>
                                                        <h3>Or</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group" style={{ textAlign: "center" }}>
                                                        <h4>New to Education Capsule? <NavLink to="/register" style={{ color: '#c42c89' }}>join now</NavLink></h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </LoadingOverlay>
    );
};

export default LoginPage;

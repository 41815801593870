import React from "react";
import '../assets/css/style.css'

import LoadingOverlay from 'react-loading-overlay';
import * as Constant from './Constant'
import './contact.css'
import contact_img from '../assets/img/subjects/contact_img.png'
import ReCAPTCHA from 'react-google-recaptcha';

class ContactUs extends React.Component {

  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      number: '',
      message: '',
      what_we_help: '',
      isLoading: false,

      nameError: null,
      emailError: null,
      numberError: null,
      messageError: null,
      what_we_helpError: null,
      isActive: false,
      captchaText: '',
      userInput: '',
      isCaptchaValid: false,
      captchError: null,
      num1: Math.floor(Math.random() * 10) + 1,
      num2: Math.floor(Math.random() * 10) + 1,
      answer: '',
      isCorret: '',
    }

    this.recaptchaRef = React.createRef();

  }

  handleUserInput = (event) => {
    this.setState({ userInput: event.target.value })
  };


  validate = () => {
    let nameError = '';
    let emailError = '';
    let numberError = '';
    let messageError = '';
    let what_we_helpError = '';

    if (!this.state.name) {
      nameError = "Please Enter Name"
    }

    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Please Enter Email Address.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please enter a valid Email Address.";
      }
    }

    if (!this.state.number) {
      numberError = "Please Enter Contact Number."
    } else {
      if (this.state.number.length <= 7) {
        numberError = "Phone number cannot be less than eight digit"
      }
    }

    if (!this.state.message) {
      messageError = "Please Enter Message."
    }

    console.log(this.state.what_we_help)
    if (this.state.what_we_help == '') {
      what_we_helpError = "Please Select What Can We Help You."
    }

    // if (!this.state.userInput) {
    //   captchError = "Please Enter Captcha Code."
    // } else {
    //   if (parseInt(this.state.userInput, 10) !== expectedAnswer) {
    //     captchError = "Please Enter Valid Captcha."
    //   }
    // }


    if (nameError || emailError || numberError || messageError || what_we_helpError) {
      this.setState({
        nameError,
        emailError,
        numberError,
        messageError,
        what_we_helpError
      });

      return false;
    }

    console.log('name', this.state.name)
    console.log('number', this.state.number)
    console.log('email', this.state.email)
    console.log('message', this.state.message)


    return true;
  }


  onSubmit = async () => {

    if (this.validate()) {
      this.setState({
        nameError: null,
        emailError: null,
        numberError: null,
        messageError: null,
        captchError: null
      });
      this.onContact();
    }
  }


  onContact = async () => {
    this.setState({ isLoading: true, isActive: true })
    const recaptchaValue = await this.recaptchaRef.current.executeAsync();
    console.log(recaptchaValue)
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("number", this.state.number);
    formData.append("message", this.state.message);
    formData.append("what_we_help", this.state.what_we_help);
    formData.append("recaptchaToken",recaptchaValue);

    await fetch(Constant.ContactUs, {
      method: "POST",
      body: formData,
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          this.setState({ isLoading: false, isActive: true })
          window.location.href = Constant.BaseUrl + 'thank-you-contact'
        } else {
          this.setState({ isLoading: false, isActive: true })
        }
      })
  }

  handleChangeName = event => {
    const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
    this.setState({ name: result })
  };

  handleChangePhone = event => {
    const result = event.target.value.replace(/[^0-9]/gi, '');
    this.setState({ number: result })
  };

  handleChangeHelp = event => {
    this.setState({ what_we_help: event.target.value })
  };

  render() {
    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        fadeSpeed={10}
        text='Message Sending...'
      >
        <section className="contact_us_main">
          <div className="bg-black pb-5" style={{ border: 'none', borderRadius: 60 }}>
            <p className="text-center pt-5" style={{ fontWeight: 700, fontSize: 38, color: '#fff' }}>Contact us</p>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10 ">
                <div className="row mt-5">
                  <div className="col-md-6 form-group">
                    <input type="text" placeholder="Full Name" value={this.state.name}
                      onChange={this.handleChangeName} name="name" className="form-control contactradius" />
                    {!!this.state.nameError && (
                      <text style={{ color: "red", marginLeft: "10px" }}>
                        {this.state.nameError}
                      </text>
                    )}
                  </div>
                  <div className="col-md-6 form-group">
                    <input type="text" placeholder="Phone Number" name="number"
                      value={this.state.number} onChange={this.handleChangePhone} minLength="11"
                      maxLength="10" className="form-control contactradius" />
                    {!!this.state.numberError && (
                      <text style={{ color: "red", marginLeft: "10px" }}>
                        {this.state.numberError}
                      </text>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <input type="text" name="email"
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    } placeholder="Email Address" className="form-control contactradius" />
                  {!!this.state.emailError && (
                    <text style={{ color: "red", marginLeft: "10px" }}>
                      {this.state.emailError}
                    </text>
                  )}
                </div>
                <div className="form-group">
                  <select name="what_we_help" placeholder="What can we help you with? " onChange={this.handleChangeHelp} className="contactradius form-control">
                    <option value="">What can we help you with?</option>
                    <option value="ask_a_query">Ask a query</option>
                    <option value="book_math_session">Book Math Session</option>
                    <option value="book_eng_session">Book English session</option>
                    <option value="book_sci_session">Book Science session</option>
                    <option value="book_com_session">Book Computing session</option>
                    <option value="com_exam">Book a session for Competitive exams</option>
                  </select>

                  {!!this.state.what_we_helpError && (
                    <text style={{ color: "red", marginLeft: "10px" }}>
                      {this.state.what_we_helpError}
                    </text>
                  )}
                </div>
                <div className="form-group">
                  <textarea className="form-control contactradius pt-3" onChange={(event) =>
                    this.setState({ message: event.target.value })
                  } name="message" placeholder="Type message here..." style={{ height: 250, borderRadius: 25 }}></textarea>
                  {!!this.state.messageError && (
                    <text style={{ color: "red", marginLeft: "10px" }}>
                      {this.state.messageError}
                    </text>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <ReCAPTCHA
                      sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                      size="invisible"
                      ref={this.recaptchaRef}
                    />
                    {/* <div className="d-flex align-items-center robot_input form-group">
                      <span style={{ marginRight: 10 }}>I’m not a robot</span>
                      <span className="equation colorBlack"> {this.state.num1} + {this.state.num2} =</span>
                      <input
                        type="text"
                        className="form-control text_field_robot"
                        name="userInput "
                        style={{ borderRadius: 18 }}
                        value={this.state.userInput}
                        onChange={(e) => this.setState({ userInput: e.target.value })}
                      />
                    </div>
                    {!!this.state.captchError && (
                      <text style={{ color: "red", marginLeft: "10px" }}>
                        {this.state.captchError}
                      </text>
                    )} */}

                  </div>
                  <div className="col-md-4">
                    <div className="actions">
                      <button
                        style={{ width: '100%' }}
                        type="submit"
                        name="submit"
                        onClick={() => this.onSubmit()}
                        className="lending-btn demo-btn "
                        title="Submit Your Message!"
                      >
                        Send
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-black pb-5" style={{ border: 'none' }}>
          <div className="container" >
            <div className="row">
              <div className="col-md-6 mt-2">
                <p className="contact_head">Build your<br></br><br></br> Child’s Future<br></br><br></br> with us</p>
                <p className="contact_sub mt-5" style={{ width: 300 }}>Unlock limitless potential and shape a brighter tomorrow – Join Education Capsule today!</p>
              </div>
              <div className="col-md-6">
                <img src={contact_img} alt="Contact Image" />
              </div>
            </div>
          </div>
        </section>
      </LoadingOverlay>
    );
  }
}

export default ContactUs;
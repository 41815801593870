import React from "react";
import '../components/home.css'

import test1 from '../assets/img/newhomepage/10.png'
import test2 from '../assets/img/newhomepage/12.png'
import test3 from '../assets/img/newhomepage/08.png'
import test4 from '../assets/img/newhomepage/09.png'

import math from '../assets/img/newhomepage/maths.jpg'
import seven from '../assets/img/newhomepage/seven_exam.png'
import eleven from '../assets/img/newhomepage/eleven_exam.png'
import thirteen from '../assets/img/newhomepage/thirteen_exam.png'
import computing from '../assets/img/newhomepage/computing.jpg'
import science from '../assets/img/newhomepage/science.jpg'
import english from '../assets/img/newhomepage/eng.jpg'
import stas from '../assets/img/newhomepage/stas.png'
import trust_pilot from '../assets/img/trust_pilot.png'

import signup from '../assets/img/newhomepage/sign_up.png'
import request_demo from '../assets/img/newhomepage/request_demo.png'
import start_session from '../assets/img/newhomepage/start_session.png'

import Carousel from 'react-bootstrap/Carousel';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import one_to_one from '../assets/img/newhomepage/one_to_one.png'
import round_clock from '../assets/img/newhomepage/round_clock.png'
import video_lession from '../assets/img/newhomepage/video_lession.png'
import practice_quiz from '../assets/img/newhomepage/practic_quiz.png'
import reviewgoogle from '../assets/img/newhomepage/googlereview1.png'
import LazyVideo from "./LazyVideo";
import LazyVideo2 from "./LazyVideo2";

import * as Constant from './Constant'
import '../assets/css/stylenew.css'

import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import VideoEmbed from './VideoEmbed';
import yellow_star from '../assets/img/yellow_star.png'


class Homepagenew extends React.Component {

    constructor() {
        super();
        this.state = {
            country_id: '',
            name: '',
            email: '',
            phone: '',
            tabone: true,
            tabtwo: false,
            tabthree: false,
            tabfour: false,
            tabfive: false,

            isLoading: false,

            nameError: null,
            emailError: null,
            phoneError: null,
            captchaText: '',
            userInput: '',
            isCaptchaValid: false,
            answer: '',
            isCorret: '',

            CountryList: [],
            SubjectList: [],
            subject_id: '',
            level_id: '',
            LevelList: [],
            level_idError: null,
            subject_idError: null,
            country_idError: null

        }

        this.recaptchaRef = React.createRef();
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
    }



    onSubmit = () => {
        if (this.validate()) {
            this.setState({
                nameError: null,
                emailError: null,
                phoneError: null,
            })
            this.onEnrollDemo();
        }
    }

    validate = () => {
        let nameError = '';
        let emailError = '';
        let phoneError = '';
        let level_idError = '';
        let subject_idError = '';
        let country_idError = '';


        if (!this.state.name) {
            nameError = "Please Enter Name"
        }

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!this.state.email) {
            emailError = "The Email Address cannot be empty.";
        } else {
            if (!reg.test(this.state.email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }

        if (this.state.level_id.length == 0) {
            level_idError = "Please select level"
        }

        if (this.state.subject_id.length == 0) {
            subject_idError = "Please select subject"
        }

        if (this.state.country_id.length == 0) {
            country_idError = "Please select country"
        }

        if (!this.state.phone) {
            phoneError = "Please Enter Contact Number"
        } else {
            if (this.state.phone.length <= 7) {
                phoneError = "Phone number cannot be less than eight digit"
            }
        }

        if (level_idError || subject_idError || nameError || emailError || phoneError || country_idError) {
            this.setState({
                level_idError,
                subject_idError,
                nameError,
                emailError,
                phoneError,
                country_idError
            });

            return false;
        }

        return true;
    }

    onEnrollDemo = async () => {
        this.setState({ isLoading: true })

        const recaptchaValue = await this.recaptchaRef.current.executeAsync();

        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("phone", this.state.phone);
        formData.append("country_id", this.state.country_id);
        formData.append("subject_id", this.state.subject_id);
        formData.append("level_id", this.state.level_id);
        formData.append("recaptchaToken", recaptchaValue);

        await fetch(Constant.FreeDemo, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({ isLoading: false })
                    this.setState({
                        name: '',
                        email: '',
                        phone: '',
                        country_id: '',
                        subject_id: '',
                        level_id: ''

                    })

                    toast.success('Your request forwarded successfully..', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    this.setState({ isLoading: false })
                }
            })
    }

    handleChangeLastName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        this.setState({ name: result })
    };

    handleChangeSubject = (e) => {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value);
    }

    handleChangeLevel = (e) => {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ level_id: e.target.value });
    }

    handleChangePhone = event => {
        const result = event.target.value.replace(/[^0-9]/gi, '');
        this.setState({ phone: result })
    };



    ShowTab = (value) => {
        if (value === 'tabone') {
            this.setState({ tabone: true, tabthree: false, tabtwo: false, tabfour: false, tabfive: false })
        } else if (value === 'tabtwo') {
            this.setState({ tabtwo: true, tabthree: false, tabone: false, tabfour: false, tabfive: false })
        } else if (value === 'tabthree') {
            this.setState({ tabtwo: false, tabthree: true, tabone: false, tabfour: false, tabfive: false })
        }
        else if (value === 'tabfour') {
            this.setState({ tabtwo: false, tabthree: false, tabone: false, tabfour: true, tabfive: false })
        }
        else {
            this.setState({ tabtwo: false, tabthree: false, tabone: false, tabfour: false, tabfive: true })
        }
    }

    componentDidMount = () => {
        this.getSubject();
        this.getCountry();
    }

    getCountry = async () => {
        await fetch(Constant.getCountry, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    // console.log(data.data)
                    this.setState({ CountryList: data.data })
                }
            })
    }

    getSubject = async () => {

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })
    }

    getLevel = async (value) => {

        await fetch(Constant.getLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        LevelList: data.data,
                        isLoading: false
                    })
                }
            })
    }


    render() {
        var userId = localStorage.getItem('userId')
        var roleId = localStorage.getItem('roleId')
        if (userId === null) {
            return (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                            </div>
                            <div className="col-md-4 learning_form_responsive">
                                <div className="learning-form freedomestyle">
                                    <h1>Book Free Lesson Class</h1>
                                    <p>Learn From Best Online Tutors</p>
                                    <div className="slider-form-outer">
                                        <div className="form-group">
                                            <input name="name" autoComplete="on" value={this.state.name} onChange={this.handleChangeLastName} type="text" className="form-control" placeholder="Enter Your Name" id="name" />
                                            {!!this.state.nameError && (
                                                <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                                    {this.state.nameError}
                                                </text>
                                            )}

                                            <input name="phone" autoComplete="on" value={this.state.phone} onChange={this.handleChangePhone} type="text" className="form-control" placeholder="Enter Your Phone Number" id="phone" maxLength="11" />
                                            {!!this.state.phoneError && (
                                                <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                                    {this.state.phoneError}
                                                </text>
                                            )}

                                            <input name="email" value={this.state.email} onChange={(event) =>
                                                this.setState({ email: event.target.value })
                                            } type="email" autoComplete="on" className="form-control" placeholder="Email Address" id="email" />
                                            {!!this.state.emailError && (
                                                <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                                    {this.state.emailError}
                                                </text>
                                            )}

                                            <select name="subject_id" id="subject_id" onChange={this.handleChangeSubject} value={this.state.subject_id} className="form-control country_dropdown mt-1">
                                                <option value="">Select Subject</option>
                                                {
                                                    this.state.SubjectList.map((item, i) => (
                                                        <option key={i} value={item.id}>{item.title}</option>
                                                    ))
                                                }

                                            </select>
                                            {!!this.state.subject_idError && (
                                                <text style={{ color: "#000", marginLeft: "10px" }}>
                                                    {this.state.subject_idError}
                                                </text>
                                            )}

                                            {
                                                this.state.LevelList.length === 0 ? <select className="form-control mt-1"><option>Select Level</option></select> :
                                                    <select name="level_id" autoComplete="on" value={this.state.level_id} id="level_id" onChange={this.handleChangeLevel} className="form-control mt-1">
                                                        <option value="">Select Level</option>
                                                        {
                                                            this.state.LevelList.map((item, i) => (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            ))
                                                        }

                                                    </select>
                                            }
                                            {!!this.state.level_idError && (
                                                <text style={{ color: "#000", marginLeft: "10px" }}>
                                                    {this.state.level_idError}
                                                </text>
                                            )}

                                            <select name="country_id" id="country_id" value={this.state.country_id} onChange={(event) => this.setState({ country_id: event.target.value })} className="form-control country_dropdown mt-1">
                                                <option value="">Select Country</option>
                                                {
                                                    this.state.CountryList.map((item, i) => (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {!!this.state.country_idError && (
                                                <text style={{ color: "#000", marginLeft: "10px" }}>
                                                    {this.state.country_idError}
                                                </text>
                                            )}

                                            <div className="d-flex align-items-center ">
                                                <ReCAPTCHA
                                                    sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                                                    size="invisible"
                                                    ref={this.recaptchaRef}
                                                />
                                            </div>
                                            <div className="col-sm-6 ml-auto" >
                                                <button
                                                    type="submit"
                                                    name="submit"
                                                    onClick={() => this.onSubmit()}
                                                    className="btnRegister enrolldemo"
                                                >

                                                    {this.state.isLoading ? (
                                                        <ClipLoader
                                                            type="TailSpin"
                                                            color="#ffffff"
                                                            height="20"
                                                            width="20"
                                                        />
                                                    ) : (
                                                        "Book Demo Lessons"
                                                    )}

                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Carousel>
                        <Carousel.Item>
                            <section id="homepage-main-new">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 banner_margin " >
                                            <h1 className="banner-text1 home_banner_text small_text">Personalised Sessions</h1>
                                            <h2 className="font banner-text2">Education Capsule specializes in crafting tailor-made programs to cater to each student's unique learning preferences and goals.
                                            </h2>
                                            <a href="/book-a-tutor" className="lending-btn" > Book Demo Lesson</a>
                                        </div>
                                    </div>
                                </div>
                            </section >
                        </Carousel.Item>
                        <Carousel.Item>
                            <section id="homepage-main-new2">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 banner_margin" >
                                            <h1 className="banner-text1 home_banner_text">Flexible Hours</h1>
                                            <h2 className="font banner-text2">Education Capsule offers flexible hours to accommodate diverse schedules, making learning convenient and accessible for all our students.</h2>
                                            <a href="/book-a-tutor" className="lending-btn" > Book Demo Lesson</a>
                                        </div>
                                    </div>
                                </div>
                            </section >
                        </Carousel.Item>
                        <Carousel.Item>
                            <section id="homepage-main-new3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 banner_margin" >
                                            <h1 className="banner-text1 home_banner_text">Practice quizzes</h1>
                                            <h2 className="font banner-text2">Education Capsule provides multi-level practice quizzes to help students reinforce their knowledge and progress through their learning journey with confidence.
                                            </h2>
                                            <a href="/book-a-tutor" className="lending-btn" > Book Demo Lesson</a>
                                        </div>
                                    </div>
                                </div>
                            </section >
                        </Carousel.Item>
                        <Carousel.Item>
                            <section id="homepage-main-new4">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 banner_margin" >
                                            <h1 className="banner-text1 home_banner_text">Pre and Post assessment</h1>
                                            <h2 className="font banner-text2">At Education Capsule, we incorporate both pre and post-assessments to tailor our programs and track your progress effectively.</h2>
                                            <a href="/book-a-tutor" className="lending-btn" > Book Demo Lesson</a>

                                        </div>
                                    </div>
                                </div>
                            </section >
                        </Carousel.Item>
                    </Carousel>
                    <ToastContainer />
                    <TawkMessengerReact
                        propertyId="61432765d326717cb681c6ee"
                        widgetId="1ffn53prq" />

                    <div className=" mt-4 video_none">

                        <h2 className="largefont mt-5">Education Capsule Delivers</h2>
                        <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 mt-3 text-center responsive">
                            <LazyVideo />
                            {/* <video className="videowidthh" width="700" poster={poster1} controls><source src={video_two} type="video/mp4" /></video> */}
                        </div>

                        <div className="row mt-5 " style={{ marginLeft: '8%', marginRight: '8%' }}>
                            <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 deliver_sec">
                                <div className="deliver_class  ">
                                    <img src={one_to_one} alt="one_to_one" />
                                    <p className="sec_2_text" >One-to-one personalised session</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 deliver_sec">
                                <div className="deliver_class  ">
                                    <img src={round_clock} alt="round_clock" />
                                    <p className="sec_2_text" >We are available  around the clock</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 deliver_sec">
                                <div className="deliver_class  ">
                                    <img src={video_lession} alt="video_lession" />
                                    <p className="sec_2_text" >Learn with conceptual video lessons</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 deliver_sec">
                                <div className="deliver_class  ">
                                    <img src={practice_quiz} alt="practic_quiz" />
                                    <p className="sec_2_text">We provide engaging practice quizzes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="largefont mt-4">Testimonials</h2>
                    <div className="faq_sec mt-4 mb-5 pt-5 pb-5" style={{ marginLeft: '8%', marginRight: '8%' }}>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6 d-flex justify-content-center">
                                <VideoEmbed />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6">
                                <Carousel>
                                    <Carousel.Item>
                                        <div className="row  mobile">
                                            <div className=" testcard col-md-12 col-lg-12 col-xl-12 " >
                                                <div className="col-lg-4 col-md-4 col-xl-4">
                                                    <img src={test1} alt="video" className="border-radius " />
                                                    <h4 className="text-center mt-2">Ryan's Father</h4>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-xl-8">
                                                    <p className="card-textnew colorBlack p-2 bold" >
                                                        I can see the improvements in my son. He is much more confident, his grades have improved, and most importantly, he enjoys learning from the Education Capsule.</p>
                                                    <div className="row review">
                                                        <img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="row mobile">
                                            <div className=" testcard col-md-5 col-lg-5 col-xl-5 " >
                                                <div className="col-lg-4 col-md-4 col-xl-4 ">
                                                    <img src={test2} alt="image" className="border-radius " />
                                                    <h4 className="text-center mt-2">Henry </h4>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-xl-8">
                                                    <p className="card-textnew colorBlack p-2 bold" >
                                                        Thanks to my tutor for motivating me to try even if the problem I was solving was complex. Whenever I got something wrong, My tutor helped me and did not let me give up. Now I feel more confident.
                                                    </p>
                                                    <div className="row review">
                                                        <img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="row mobile">
                                            <div className=" testcard col-md-5 col-lg-5 col-xl-5 " >
                                                <div className="col-lg-4 col-md-4 col-xl-4">
                                                    <img src={test3} alt="video" className="border-radius " />
                                                    <h4 className="text-center mt-2">Olivia </h4>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-xl-8">
                                                    <p className="card-textnew colorBlack p-2 bold" >
                                                        My tutor made learning fun in all the sessions. She related maths with day-to-day life to help me understand better. My tutor helped me and did not let me give up. Now I feel more confident.
                                                    </p>
                                                    <div className="row review">
                                                        <img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="row mobile">
                                            <div className=" testcard col-md-5 col-lg-5 col-xl-5 " >
                                                <div className="col-lg-4 col-md-4 col-xl-4 ">
                                                    <img src={test4} alt="image" className="border-radius" />
                                                    <h4 className="text-center mt-2">Bella's mother</h4>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-xl-8">
                                                    <p className="card-textnew colorBlack p-2 bold" >
                                                        Thanks a lot to Education capsule for helping my daughter with her SATs. The multi-level practice tests helped her a lot. I would happily recommend joining the Education capsule.
                                                    </p>
                                                    <div className="row review">
                                                        <img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} /><img src={yellow_star} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>

                        </div>
                    </div>

                    <div className="mt-5 bg-black ">
                        <div className="row">
                            <div className="col-md-4 pt-5" style={{ paddingLeft: 45 }}>
                                <div className="row">
                                    <div className="col-md-11">
                                        <div className="reviwesfont1">5000+</div>
                                        <div className="reviwesfont2">Student Enrolled</div>
                                    </div>
                                    <div className="col-md-1 mt-5 mobile_none">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                            <path d="M1 0V63.4615" stroke="white" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 pt-5 ">
                                <div className="row">
                                    <div className="col-md-11 mt-4 text-center">
                                        {/* <h4 className="reviwesfont">Trustpilot rating</h4> */}
                                        <img src={trust_pilot} alt="star" />
                                        {/* <h4 className="reviwesfont">Reviews</h4> */}
                                    </div>
                                    <div className="col-md-1 mt-5 mobile_none">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                            <path d="M1 0V63.4615" stroke="white" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 text-center" style={{ padding: 50 }}>
                                <img src={reviewgoogle} alt='reviews' />
                            </div>

                        </div>
                    </div>

                    <div className="mt-3">
                        <h2 className="largefont">Subjects we teach</h2>
                        <p className="subheading">We assess your child's abilities and style of learning and provide<br></br>personalised and adaptive one-to-one sessions</p>
                        <div className="row mobile" >
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={math} alt="math" />
                                </div>
                                <h5 className="subject-title" >Mathematics</h5>
                                <p className="card-textnew" >Education Capsule has the best Math Tutors in the world. Our Maths Tutors are trusted and recommended by hundreds of parents and teachers across the UK.</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={science} alt="science" />
                                </div>
                                <h5 className="subject-title">Science</h5>
                                <p className="card-textnew" >We have a group of Science experts who are qualified enough to make learning fun and effective for kids. We help students from school to the university level.</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={english} alt="eleven" />
                                </div>
                                <h5 className="subject-title">English</h5>
                                <p className="card-textnew" >We provide everything a learner needs to become a confident and fluent English language speaker. We offer tailor-made English Language courses which include Grammar, Reading, Speaking, Listening and Writing Skills.</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={computing} alt="computing" />
                                </div>
                                <h5 className="subject-title">Computing</h5>
                                <p className="card-textnew" >Education Capsule provides one-to-one sessions on Computing up to university and higher levels. Students who learn to code and who learn about computer science can become independent adults in a technology-rich society.</p>

                            </div>
                        </div>

                        <h2 className="largefont mt-5">Exam Preparation</h2>
                        <p className="subheading">Education Capsule provides experienced tutors, practice multi-level quizzes,and <br></br> doubt-clearing sessions to help students excel.</p>

                        <div className="row mobile" >
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={stas} alt="stas" />
                                </div>
                                <h5 className="subject-title">SATs</h5>
                                <p className="card-textnew" >We focus on minimizing the gap in the student’s knowledge level. Through effective revision classes and practice papers, we help students to excel in their SATs.</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={seven} alt="seven" />
                                </div>
                                <h5 className="subject-title">7+ Exam</h5>
                                <p className="card-textnew" >Our 7+ exam preparation course is designed to help students to achieve success in their exams. Our tutors are well-versed in the requirements of the 7+ exam.</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={eleven} alt="eleven" />
                                </div>
                                <h5 className="subject-title">11+ Exam</h5>
                                <p className="card-textnew " >Education Capsule has a strong performance history when it comes to the 11+ Exam. We are specialists in this field and provide personalised one on one classes</p>

                            </div>
                            <div className="col-xl-3 col-md-3 col-sm-12 col-xs-12 cardnew">
                                <div className="imagecard">
                                    <img src={thirteen} alt="thirteen" className="sliderimage" />
                                </div>
                                <h5 className="subject-title">13+ Exam</h5>
                                <p className="card-textnew " >Every tutor we recommend has outstanding experience and a proven track record in helping children excel in 13+ exams. The sessions are tailored to the pupil’s level of understanding to provide excellent support. </p>
                            </div>
                        </div>

                        <h2 className="largefont mt-4"  >Start your learning journey with us</h2>
                        <div className="row mt-5">
                            <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12  text-center">
                                <img src={signup} alt="sign Up" />
                                <br></br>
                                <div style={{ display: 'flex' }}>
                                    <a href="/register" className="lending-btn bookdemo" > Sign Up</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12 text-center" >
                                <img src={request_demo} alt="Request Demo" /><br></br>
                                <div style={{ display: 'flex' }}>
                                    <a href="/book-a-tutor" className="lending-btn bookdemo" > Request Lesson</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12  text-center">
                                <img src={start_session} alt="start lesson" /><br></br>
                                <div style={{ display: 'flex' }}>
                                    <a href="/book-a-tutor" class="lending-btn bookdemo">Start Lesson</a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="mt-5 bckgrngimg video_none">
                        <div className="mt-5 pt-4">
                            <h2 className="text-center howdowework_font">Why Choose Education Capsule?</h2>
                        </div>

                        <div className="mt-5">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-xs-12 text-center">
                                    <LazyVideo2 />
                                    {/* <video className="videowidthh" width="700" poster={poster2} controls><source src={video_one} type="video/mp4" /></video> */}
                                </div>
                                <div className="mt-3">
                                    <h3 className="howdowework_font text-center">Education capsule offers one-to-one learning sessions by our best teachers.</h3>
                                </div>
                                <div className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-xs-12">
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="mt-5">
                        <p className="largefont fontsize colorOrange" style={{ lineHeight: 'inherit' }} >Testimonials by Students</p>
                        <p className="subheading" >Education Capsule is trusted by parents and loved by students.</p>

                        <Carousel>
                            <Carousel.Item>
                                <div className="row mb-4 mt-5 mobile">
                                    <div className=" testcard col-md-5 col-lg-5 col-xl-5 " >
                                        <div className="col-lg-4 col-md-4 col-xl-4">
                                            <img src={test1} alt="video" className="border-radius " />
                                            <h4 className="text-center mt-2">Ryan's Father</h4>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-xl-8">
                                            <p className="card-textnew colorBlack p-2 bold" >
                                                I can see the improvements in my son. He is much more confident, his grades have improved, and most importantly, he enjoys learning from the Education Capsule.</p>
                                        </div>
                                    </div>

                                    <div className=" testcard col-md-5 col-lg-5 col-xl-5 " >
                                        <div className="col-lg-4 col-md-4 col-xl-4 ">
                                            <img src={test2} alt="image" className="border-radius " />
                                            <h4 className="text-center mt-2">Henry </h4>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-xl-8">
                                            <p className="card-textnew colorBlack p-2 bold" >
                                                Thanks to my tutor for motivating me to try even if the problem I was solving was complex. Whenever I got something wrong, My tutor helped me and did not let me give up. Now I feel more confident.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="row mb-4 mt-5 mobile">
                                    <div className=" testcard col-md-5 col-lg-5 col-xl-5 " >
                                        <div className="col-lg-4 col-md-4 col-xl-4">
                                            <img src={test3} alt="video" className="border-radius " />
                                            <h4 className="text-center mt-2">Olivia </h4>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-xl-8">
                                            <p className="card-textnew colorBlack p-2 bold" >
                                                My tutor made learning fun in all the sessions. She related maths with day-to-day life to help me understand better. My tutor helped me and did not let me give up. Now I feel more confident.
                                            </p>
                                        </div>
                                    </div>

                                    <div className=" testcard col-md-5 col-lg-5 col-xl-5 " >
                                        <div className="col-lg-4 col-md-4 col-xl-4 ">
                                            <img src={test4} alt="image" className="border-radius" />
                                            <h4 className="text-center mt-2">Bella's mother</h4>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-xl-8">
                                            <p className="card-textnew colorBlack p-2 bold" >
                                                Thanks a lot to Education capsule for helping my daughter with her SATs. The multi-level practice tests helped her a lot. I would happily recommend joining the Education capsule.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div> */}

                    {/* <div className="mt-4">
                        <div className="row">
                            <div className="col-md-6 d-flex justify-content-center">
                                <VideoEmbed />
                            </div>
                            <div className="col-md-6 ">
                                <h1 className="mt-3 ms-5 FAQS">Reviews</h1>
                                <Carousel className="d-flex justify-content-center">
                                    <Carousel.Item>
                                        <div className="reviewCard col-md-12 col-lg-12 col-xl-12 " >
                                            <p className="card-textnew colorBlack p-2 bold" >
                                                Thanks a lot to Education capsule for helping my daughter with her SATs. The multi-level practice tests helped her a lot. I would happily recommend joining the Education capsule.
                                            </p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="reviewCard col-md-12 col-lg-12 col-xl-12 " >
                                            <p className="card-textnew colorBlack p-2 bold" >
                                                My tutor made learning fun in all the sessions. She related maths with day-to-day life to help me understand better. My tutor helped me and did not let me give up. Now I feel more confident.
                                            </p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="reviewCard col-md-12 col-lg-12 col-xl-12 " >
                                            <p className="card-textnew colorBlack p-2 bold" >
                                                Thanks to my tutor for motivating me to try even if the problem I was solving was complex. Whenever I got something wrong, My tutor helped me and did not let me give up. Now I feel more confident.
                                            </p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="reviewCard col-md-12 col-lg-12 col-xl-12 " >
                                            <p className="card-textnew colorBlack p-2 bold" >
                                                I can see the improvements in my son. He is much more confident, his grades have improved, and most importantly, he enjoys learning from the Education Capsule.
                                            </p>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div> */}

                    <div className="faq_sec mt-4 mb-5 pb-5" style={{ marginLeft: '8%', marginRight: '8%' }}>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6">
                                <h1 className="mt-3 ms-5 FAQS">FAQs</h1>
                                <div className="mt-4 mb-5" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <div className={this.state.tabone === true ? "faq_question focustab" : "faq_question"} data-toggle="tab" onClick={() => this.ShowTab('tabone')} role="tab">What services does education capsule provide?</div>
                                    <div className={this.state.tabtwo === true ? "faq_question focustab mt-3" : "faq_question mt-3"} data-toggle="tab" onClick={() => this.ShowTab('tabtwo')} role="tab">How do I get started?</div>
                                    <div className={this.state.tabthree === true ? "faq_question focustab mt-3" : "faq_question mt-3"} data-toggle="tab" onClick={() => this.ShowTab('tabthree')} role="tab">What subjects do you offer tutoring in?</div>
                                    <div className={this.state.tabfour === true ? "faq_question focustab mt-3" : "faq_question mt-3"} data-toggle="tab" onClick={() => this.ShowTab('tabfour')} role="tab">How does education capsule work?</div>
                                    <div className={this.state.tabfive === true ? "faq_question focustab mt-3" : "faq_question mt-3"} data-toggle="tab" onClick={() => this.ShowTab('tabfive')} role="tab">Can you accommodate flexible tutoring schedules?</div>
                                </div>

                            </div>
                            <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12 col-xl-5">
                                <h1 className="mt-3 ms-5 FAQS" style={{ marginLeft: "6%" }}>Answers</h1>
                                <div className="mt-4 mb-5" style={{ height: '75%' }} >
                                    {this.state.tabone === true ?
                                        <div className="faq_anss">
                                            <p className="pragraftab mt-3">
                                                Education Capsule is an online tutoring platform with qualified tutors. We
                                                provide one-to-one personalised tutors from the school level to the university level with
                                                conceptual clarity over various subjects. We also help students to prepare for SATs,
                                                GCSE, 11+, 13+, A level etc.
                                            </p>
                                            <Link type="submit" className="faqbutn mt-5 text-center" to='contact-us'>Know more</Link>
                                        </div>
                                        : ''}

                                    {this.state.tabtwo === true ?
                                        <div className="faq_anss">
                                            <p className="pragraftab mt-3">
                                                Sign up using the ‘Join Now’ button and submit the ‘Book Lesson Now’ Form
                                                to book your free demo lesson with Education Capsule. Once done with the demo
                                                lesson, you can connect via https://educationcapsule.com/contact-us to proceed
                                                further.
                                            </p>
                                            <Link type="submit" className="faqbutn mt-5 text-center" to='contact-us'>Know more</Link>
                                        </div>
                                        : ''}

                                    {this.state.tabthree === true ?
                                        <div className="faq_anss">
                                            <p className="pragraftab mt-3">
                                                We provide tutoring services for a wide range of subjects, with the most
                                                popular ones being Mathematics, English, Test Preparation, Science, English as a
                                                Second Language (ESL), and many others.
                                            </p>
                                            <Link type="submit" className="faqbutn mt-5 text-center" to='contact-us'>Know more</Link>
                                        </div>
                                        : ''}
                                    {this.state.tabfour === true ?
                                        <div className="faq_anss">
                                            <p className="pragraftab mt-3">
                                                Upon booking live sessions with us, we begin with a pre-assessment to gauge
                                                the student's needs, and then tailor lessons accordingly. Additionally, we offer multi-
                                                level quizzes for practice.
                                            </p>
                                            <Link type="submit" className="faqbutn mt-5 text-center" to='contact-us'>Know more</Link>
                                        </div>
                                        : ''}
                                    {this.state.tabfive === true ?
                                        <div className="faq_anss">
                                            <p className="pragraftab mt-3">
                                                Education capsule is available around the clock, ensuring flexibility to meet your
                                                learning needs at any time. Whether it's day or night, we're here to support your
                                                education journey. Tutors are available to assist you whenever you require their
                                                expertise.
                                            </p>
                                            <Link type="submit" className="faqbutn mt-5 text-center" to='contact-us'>Know more</Link>
                                        </div>
                                        : ''}

                                </div>
                            </div>
                            <div className="col-md-1 col-lg-1 col-sm-12 col-xs-12 col-xl-1"></div>
                        </div>
                    </div>
                </ >
            )
        } else {
            if (roleId == 3) {
                window.location.href = Constant.BaseUrl + "student-dashboard"
            } else {
                window.location.href = Constant.BaseUrl + "tutor-dashboard"
            }
        }

    }


}
export default Homepagenew;

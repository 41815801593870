import React from "react";
import * as Constant from '../components/Constant'

import LoadingOverlay from 'react-loading-overlay';
import contact_img from '../assets/img/subjects/contact_img.png'
import ReCAPTCHA from 'react-google-recaptcha';

class BookATutor extends React.Component {

    constructor() {
        super();
        this.state = {
            formone: true,
            formtwo: '',
            SubjectList: [],
            LevelList: [],
            isLoading: false,

            name: '',
            phone: '',
            email: '',
            level_id: '',
            subject_id: '',
            school_name: '',
            message: '',

            nameError: null,
            phoneError: null,
            emailError: null,
            level_idError: null,
            subject_idError: null,
            school_nameError: null,
            messageError: null,

            captchaText: '',
            userInput: '',
            isCaptchaValid: false,
            captchError: null,

            num1: Math.floor(Math.random() * 10) + 1,
            num2: Math.floor(Math.random() * 10) + 1,
            answer: '',
            isCorret: '',
            SubLevelList: [],
            sub_level_id: '',
            sublevel_idError: null
        }

        this.recaptchaRef = React.createRef();
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
        this.handleChangeSubLevel = this.handleChangeSubLevel.bind(this);
    }

    componentDidMount = () => {
        this.getSubject();
    }

    handleUserInput = (event) => {
        this.setState({ userInput: event.target.value })
    };

    getSubject = async () => {

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })
    }

    getSubLevel = async (value) => {
        this.setState({ isLoaded: true })

        await fetch(Constant.getSubLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        SubLevelList: data.data,
                        isLoaded: false
                    })
                }
            })
    }

    validate = () => {
        let nameError = '';
        let phoneError = '';
        let emailError = '';
        let level_idError = '';
        let sublevel_idError = '';
        let subject_idError = '';
        let school_nameError = '';
        let messageError = '';

        if (!this.state.name) {
            nameError = "Please enter name "
        }
        if (!this.state.phone) {
            phoneError = "Please enter phone number "
        } else {
            if (this.state.phone.length <= 7) {
                phoneError = "Phone number cannot be less than eight digit"
            }
        }

        if (!this.state.email) {
            emailError = "Please enter email address "
        }

        if (this.state.level_id.length == 0) {
            level_idError = "Please select level"
        }
        if (this.state.sub_level_id.length == 0) {
            sublevel_idError = "Please select Sub level"
        }
        if (this.state.subject_id.length == 0) {
            subject_idError = "Please select subject"
        }
        if (!this.state.school_name) {
            school_nameError = "Please enter school name"
        }
        if (!this.state.message) {
            messageError = "Please enter message"
        }

        if (level_idError || subject_idError || school_nameError || messageError || nameError || phoneError || emailError || sublevel_idError) {
            this.setState({
                level_idError,
                subject_idError,
                school_nameError,
                messageError,
                nameError,
                phoneError,
                emailError,
            })

            return false
        }

        return true;
    }

    generateNewNumbers = () => {
        this.setState({
            num1: Math.floor(Math.random() * 10) + 1,
            num2: Math.floor(Math.random() * 10) + 1,
            answer: '',
            isCorret: false
        })
    }

    handleChangeSubject = (e) => {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value);
    }

    handleChangeLevel = (e) => {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ level_id: e.target.value });
        this.getSubLevel(e.target.value)
    }

    handleChangeSubLevel = (e) => {
        console.log("sub_level_id Selected!!", e.target.value);
        this.setState({ sub_level_id: e.target.value });
    }

    onSubmit = () => {
        if (this.validate()) {
            this.setState({
                level_idError: null,
                subject_idError: null,
                school_nameError: null,
                messageError: null,
                nameError: null,
                phoneError: null,
                emailError: null,
                captchError: null
            })
            this.bookTutor();
        }
    }

    handleChangeLastName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        // const result = event.target.value.replace(/[^a-z]/gi, '');
        this.setState({ name: result })
    };
    handleChangePhone = event => {
        const result = event.target.value.replace(/[^0-9]/gi, '');
        this.setState({ phone: result })
    };


    bookTutor = async () => {
        this.setState({ isLoading: true })

        const recaptchaValue = await this.recaptchaRef.current.executeAsync();

        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("phone", this.state.phone);
        formData.append("email", this.state.email);
        formData.append("level_id", this.state.level_id);
        formData.append("sub_level_id", this.state.sub_level_id);
        formData.append("subject_id", this.state.subject_id);
        formData.append("school_name", this.state.school_name);
        formData.append("message", this.state.message);
        formData.append("recaptchaToken", recaptchaValue);

        await fetch(Constant.bookTutor, {
            method: 'POST',
            body: formData
        })
            .then(Request => Request.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false
                    })
                    window.location.href = Constant.BaseUrl + 'thank-you'
                }
            })
    }


    getLevel = async (value) => {

        await fetch(Constant.getSubjectLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        LevelList: data.data,
                        isLoading: false
                    })
                }
            })
    }

    render() {
        return (
            <LoadingOverlay
                active={this.state.isLoading}
                spinner
                fadeSpeed={10}
                text='Request Sending...'
            >
                <>

                    <div className="request_tutor" >
                        <div className="row">
                            <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12 ">
                                <p className="fontstyle" style={{ color: '#fff', textAlign: 'justify', fontSize: 42 }}>Know your tutor
                                    for free</p>

                                <p className="mt-5 banner_sub_text">Enjoy a FREE demo lesson that provides you with the perfect opportunity to assess compatibility, teaching style, and subject expertise. Take the first step towards a successful educational journey today!</p>
                            </div>
                            <div className="col-md-1 col-sm-12 col-lg-1 col-xl-1 col-xs-12"></div>
                            <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12"></div>
                        </div>
                    </div>

                    <div className="mt-5 mb-4 pink_bg" >
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="row bg-black" style={{ border: 'none', borderRadius: 60, marginTop: -410 }}>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-10 form-group">
                                        <p className="fontstyle text-center mb-5 mt-5" style={{ color: '#fff', fontSize: 45 }}>Request a tutor</p>
                                        <div className="row mt-5 ">
                                            <div className="col-md-6">
                                                <input type="text" placeholder="Full Name" name="name" value={this.state.name} onChange={this.handleChangeLastName} className="form-control contactradius" />
                                                {!!this.state.nameError && (
                                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                                        {this.state.nameError}
                                                    </text>
                                                )}

                                            </div>
                                            <div className="col-md-6 margintop">
                                                <input type="text" placeholder="Phone Number" name="phone" value={this.state.phone} onChange={this.handleChangePhone} maxLength="11" className="form-control contactradius" />
                                                {!!this.state.phoneError && (
                                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                                        {this.state.phoneError}
                                                    </text>
                                                )}
                                            </div>
                                        </div>
                                        <div className=" mt-4">
                                            <input type="email" onChange={(event) =>
                                                this.setState({ email: event.target.value })
                                            } name="email" autoComplete="on" value={this.state.email} placeholder="Email Address" className="form-control contactradius" />

                                            {!!this.state.emailError && (
                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                    {this.state.emailError}
                                                </text>
                                            )}
                                        </div>
                                        <div className="mt-4">
                                            <select className="form-control contactradius" autoComplete="on" name="subject_id" id="subject_id" value={this.state.subject_id} onChange={this.handleChangeSubject}>
                                                <option value="">Which Subject Do You Want To Learn?</option>
                                                {
                                                    this.state.SubjectList.map((item, i) => (
                                                        <option key={i} value={item.id}>{item.title}</option>
                                                    ))
                                                }

                                            </select>
                                            {!!this.state.subject_idError && (
                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                    {this.state.subject_idError}
                                                </text>
                                            )}
                                        </div>
                                        <div className=" mt-4">
                                            <div className="form-group ">
                                                {
                                                    this.state.LevelList.length === 0 ? <select className="form-control contactradius"><option>Select Level</option></select> :
                                                        <select name="level_id" autoComplete="on" value={this.state.level_id} id="level_id" onChange={this.handleChangeLevel} className="form-control contactradius">
                                                            <option value="">What Level Is This For?</option>
                                                            {
                                                                this.state.LevelList.map((item, i) => (
                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                ))
                                                            }

                                                        </select>
                                                }
                                                {!!this.state.level_idError && (
                                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                                        {this.state.level_idError}
                                                    </text>
                                                )}

                                            </div>
                                        </div>
                                        <div className=" mt-4">
                                            <div className="form-group ">
                                                {
                                                    this.state.SubLevelList.length === 0 ? <select className="form-control contactradius"><option>Select Sub Level</option></select> :
                                                        <select name="sub_level_id" autoComplete="on" value={this.state.sub_level_id} id="sub_level_id" onChange={this.handleChangeSubLevel} className="form-control contactradius">
                                                            <option value="">What Sub Level Is This For?</option>
                                                            {
                                                                this.state.SubLevelList.map((item, i) => (
                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                ))
                                                            }

                                                        </select>
                                                }
                                                {!!this.state.sublevel_idError && (
                                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                                        {this.state.sublevel_idError}
                                                    </text>
                                                )}

                                            </div>
                                        </div>
                                        <div className=" mt-4">
                                            <input type="text" onChange={(event) => this.setState({
                                                school_name: event.target.value
                                            })} name="school_name" placeholder="School name" className="form-control contactradius" />
                                            {!!this.state.school_nameError && (
                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                    {this.state.school_nameError}
                                                </text>
                                            )}
                                        </div>
                                        <div className="mt-4">
                                            <textarea className="form-control contactradius pt-3" placeholder="Type message here..." style={{ height: 220, borderRadius: 25 }} name="message" onChange={(event) => this.setState({
                                                message: event.target.value
                                            })} maxLength="300"></textarea>

                                            {!!this.state.messageError && (
                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                    {this.state.messageError}
                                                </text>
                                            )}
                                        </div>

                                        <div className="row mt-5 mb-4">
                                            <div className="col-md-6 mt-1">
                                                <div className="d-flex align-items-center " style={{ height: 55 }}>
                                                    <ReCAPTCHA
                                                        sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                                                        size="invisible"
                                                        ref={this.recaptchaRef}
                                                    />
                                                </div>

                                            </div>
                                            <div className="col-md-2"></div>
                                            <div className="col-md-4">
                                                <div className="actions">
                                                    <button
                                                        style={{ width: '100%' }}
                                                        type="submit"
                                                        name="submit"
                                                        onClick={() => this.onSubmit()}
                                                        className="lending-btn demo-btn "
                                                        title="Submit Your Message!"
                                                    >
                                                        Send Message
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>

                        <p className="head_Text text-center pt-5 pb-5" >what you get!</p>
                        <div className="row pb-5" style={{ padding: 20 }}>
                            <div className="col-md-4 imgcenter">
                                <div className="youget_div">
                                    <p className="reviwesfont mt-1 mission_heading" >Interactive<br></br> Learning Space</p>
                                    <p className="colorBlack mt-2 block_font" >Education Capsule offers a customized and interactive learning environment that supports a wide range of educational activities. Our user-friendly platform is designed for tasks such as creating an online curriculum, completing homework assignments, and preparing for entrance exams. During online sessions, two-way communication takes place using editable screens, ensuring that learning is both enjoyable and effective. Our online tutors make learning engaging by incorporating interactive exercises, games, and visual aids.</p>
                                </div>
                            </div>
                            <div className="col-md-4 imgcenter" >
                                <div className="youget_div widthauto" >
                                    <p className="reviwesfont mt-1 mission_heading" >Reliable and <br></br>Engaging Tutors </p>
                                    <p className="colorBlack mt-2 block_font" >Education Capsule is proud to have a team of reliable and engaging tutors who are experts in their respective fields. Our tutors, whether specializing in math or other subjects, undergo rigorous training to provide effective online homework help and instruction.
                                        Our dedicated tutors are committed to making learning an enjoyable experience for all students. We continuously assess and ensure the quality of our lessons, and our tutors receive ongoing training and development to stay at the forefront of education.</p>
                                </div>
                            </div>
                            <div className="col-md-4 imgcenter">
                                <div className="youget_div">
                                    <p className="reviwesfont mt-1 mission_heading" >Pre and Post<br></br> assessment</p>
                                    <p className="colorBlack mt-2 block_font" >Education Capsule employs a holistic approach to education with pre and post-assessments. Pre-assessments help customize learning plans, while ongoing assessments monitor progress. Post-assessments measure knowledge gained. This comprehensive assessment strategy ensures personalized, effective, and measurable learning outcomes for our students. The data from these assessments allows us to fine-tune our teaching methods and adapt the curriculum as needed, ensuring that students receive the most relevant and impactful education.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-black pb-5 pt-5" style={{ border: 'none', marginTop: -24 }}>
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-4 mt-5">
                                <p className="contact_head">Build your<br></br><br></br> Child’s Future<br></br><br></br> with us</p>
                                <p className="contact_sub mt-5" style={{ textAlign: 'justify', width: 300 }}>Unlock limitless potential and shape a brighter tomorrow – Join Education Capsule today!</p>
                            </div>
                            <div className="col-md-6 margintop">
                                <img src={contact_img} alt="Contact Image" />
                            </div>
                        </div>
                    </div>
                </>
            </LoadingOverlay >
        )
    }
}


export default BookATutor;
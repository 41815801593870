import React from "react";
import { Navigate } from "react-router-dom";
import * as Constant from './Constant'

import emoji1 from '../assets/img/emoji1.png'
import emoji2 from '../assets/img/emoji2.png'
import emoji3 from '../assets/img/emoji3.png'
import emoji4 from '../assets/img/emoji4.png'
import emoji5 from '../assets/img/emoji5.png'
import yellostar from '../assets/img/yellow_star.png'
import moment from "moment/moment";


class ReportsData extends React.Component {
    constructor() {
        super();

        this.state = {
            tutorname: '',
            studentname: '',
            enjoy_session: '',
            learning_resource: '',
            understandable: '',
            audio_connection: '',
            rating: '',
            message: '',
            isLoading: false,

            attractive: '',
            progress: '',
            technical_comments: '',
            red_flag: '',
            resources: '',
            date: ''

        }
    }

    componentDidMount = () => {
        this.getSessionReports();
        this.getReportTutor()
    }

    getSessionReports = async () => {
        this.setState({
            isLoading: true
        })

        var userId = localStorage.getItem('userId')
        var tutorId = localStorage.getItem('tutorId')
        // console.log(tutorId)

        await fetch(Constant.sessionReport + '?user_id=' + userId + '&tutor_id=' + tutorId, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        date: data.data[0].created_at,
                        isLoading: false,
                        tutorname: data.data[0].tutor.first_name + ' ' + data.data[0].tutor.last_name,
                        studentname: data.data[0].user.first_name + ' ' + data.data[0].user.last_name,
                        message: data.data[0].message,
                        enjoy_session: data.data[0].enjoy_session,
                        learning_resource: data.data[0].learning_resource,
                        audio_connection: data.data[0].audio_connection,
                        understandable: data.data[0].understandable,
                        rating: data.data[0].rating
                    })
                }
            })

    }

    getReportTutor = async () => {
        this.setState({
            isLoading: true
        })

        var tutorId = localStorage.getItem('userId')
        var userId = localStorage.getItem('tutorId')

        await fetch(Constant.sessionReportTutor + '?user_id=' + userId + '&tutor_id=' + tutorId, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        attractive: data.data[0].attractive,
                        progress: data.data[0].progress,
                        red_flag: data.data[0].red_flag,
                        resources: data.data[0].resources,
                        technical_comments: data.data[0].technical_comments
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            })



    }

    render() {
        var userId = localStorage.getItem("userId");
        if (userId) {
            return (
                <>
                    <section id="feature" class="atf-section-padding tutor-page student-feedback">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-xl-12">
                                    <div class="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                        <div class="student-feedback-page sessionnnn">
                                            <h1 className="colorBlack">Session Report </h1>

                                            <div class="student-feedback-form-outer newww tutor-feedback-form">
                                                <div class="row session-report-new">
                                                    <div class="col-md-6 col-xs-12">
                                                        <div class="session-outer-heading">
                                                            <h4>Date: {moment(this.state.date).format('DD-MM-YYYY')}</h4>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-xs-12">
                                                        <div class="session-outer-heading-2">
                                                            <h4>Student Name: {this.state.studentname} </h4>
                                                            <h4>Tutor Name: {this.state.tutorname}</h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6 col-xs-12">
                                                        <h4>Feedback By Student </h4>
                                                        <div class="text-bggg-new">
                                                            <h5>How much did you enjoy the session?</h5>
                                                            <div class="item">
                                                                {(() => {
                                                                    if (this.state.enjoy_session == 1) {
                                                                        return (
                                                                            <span><img src={emoji1} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.enjoy_session == 2) {
                                                                        return (
                                                                            <span><img src={emoji2} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.enjoy_session == 3) {
                                                                        return (
                                                                            <span><img src={emoji3} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.enjoy_session == 4) {
                                                                        return (
                                                                            <span><img src={emoji4} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.enjoy_session == 5) {
                                                                        return (
                                                                            <span><img src={emoji5} alt="" /> </span>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <span>-</span>
                                                                        )
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                        <div class="text-bggg-new">
                                                            <h5>How useful were the learning resources?</h5>
                                                            <div class="item">
                                                                {(() => {
                                                                    if (this.state.learning_resource == 1) {
                                                                        return (
                                                                            <span><img src={emoji1} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.learning_resource == 2) {
                                                                        return (
                                                                            <span><img src={emoji2} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.learning_resource == 3) {
                                                                        return (
                                                                            <span><img src={emoji3} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.learning_resource == 4) {
                                                                        return (
                                                                            <span><img src={emoji4} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.learning_resource == 5) {
                                                                        return (
                                                                            <span><img src={emoji5} alt="" /> </span>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <span>-</span>
                                                                        )
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                        <div class="text-bggg-new">
                                                            <h5>How understandable was the tutor?</h5>
                                                            <div class="item">
                                                                {(() => {
                                                                    if (this.state.understandable == 1) {
                                                                        return (
                                                                            <span><img src={emoji1} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.understandable == 2) {
                                                                        return (
                                                                            <span><img src={emoji2} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.understandable == 3) {
                                                                        return (
                                                                            <span><img src={emoji3} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.understandable == 4) {
                                                                        return (
                                                                            <span><img src={emoji4} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.understandable == 5) {
                                                                        return (
                                                                            <span><img src={emoji5} alt="" /> </span>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <span>-</span>
                                                                        )
                                                                    }
                                                                })()}
                                                            </div>
                                                        </div>
                                                        <div class="text-bggg-new">
                                                            <h5>How was the audio connection?</h5>
                                                            <div class="item">
                                                                {(() => {
                                                                    if (this.state.audio_connection == 1) {
                                                                        return (
                                                                            <span><img src={emoji1} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.audio_connection == 2) {
                                                                        return (
                                                                            <span><img src={emoji2} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.audio_connection == 3) {
                                                                        return (
                                                                            <span><img src={emoji3} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.audio_connection == 4) {
                                                                        return (
                                                                            <span><img src={emoji4} alt="" /> </span>
                                                                        )
                                                                    } else if (this.state.audio_connection == 5) {
                                                                        return (
                                                                            <span><img src={emoji5} alt="" /> </span>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <span>-</span>
                                                                        )
                                                                    }
                                                                })()}
                                                                {/* <span><img src={this.state.audio_connectionemoji} alt="" /> </span> */}

                                                            </div>
                                                        </div>
                                                        <div class="text-bggg-new">
                                                            <h5>Overall rating</h5>
                                                            <p style={{ width: 30, marginLeft: -15, marginTop: 18 }}>
                                                                {(() => {
                                                                    if (this.state.rating === 1) {
                                                                        return (
                                                                            <img src={yellostar} alt="yellostar" />
                                                                        )
                                                                    } else if (this.state.rating === 2) {
                                                                        return (
                                                                            <div style={{ display: 'flex' }}><img src={yellostar} alt="yellostar" /> <img src={yellostar} alt="yellostar" /></div>
                                                                        )
                                                                    } else if (this.state.rating === 3) {
                                                                        return (
                                                                            <div style={{ display: 'flex' }}><img src={yellostar} alt="yellostar" /> <img src={yellostar} alt="yellostar" /><img src={yellostar} alt="yellostar" /></div>
                                                                        )
                                                                    } else if (this.state.rating === 4) {
                                                                        return (
                                                                            <div style={{ display: 'flex' }}><img src={yellostar} alt="yellostar" /> <img src={yellostar} alt="yellostar" /> <img src={yellostar} alt="yellostar" /> <img src={yellostar} alt="yellostar" /></div>
                                                                        )
                                                                    } else if (this.state.rating === 5) {
                                                                        return (
                                                                            <div style={{ display: 'flex' }}><img src={yellostar} alt="yellostar" /><img src={yellostar} alt="yellostar" /><img src={yellostar} alt="yellostar" /> <img src={yellostar} alt="yellostar" /><img src={yellostar} alt="yellostar" /></div>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <div>-</div>
                                                                        )
                                                                    }
                                                                })()}
                                                            </p>
                                                        </div>

                                                        <div class="text-bggg-new-2">
                                                            <h5>Message</h5>
                                                            <p style={{ color: '#000' }}>
                                                                {this.state.message}
                                                            </p>

                                                        </div>


                                                    </div>

                                                    <div class="col-md-6 col-xs-12">
                                                        <h4>Feedback By Tutor </h4>
                                                        <div class="text-bggg-new-2">
                                                            <p><b>Student’s engagement</b></p>
                                                            <p className="colorBlack">
                                                                {
                                                                    this.state.attractive === 1 ? 'Attentive' : 'In-Attentive'
                                                                }
                                                            </p>
                                                        </div>
                                                        <div class="text-bggg-new-2">
                                                            <p><b>Progress Made in Session </b> </p>
                                                            <p className="colorBlack">{this.state.progress}</p>

                                                        </div>
                                                        <div class="text-bggg-new-2">
                                                            <p><b>Technical Comments (if any)</b></p>
                                                            <p className="colorBlack">{this.state.technical_comments}</p>
                                                        </div>
                                                        <div class="text-bggg-new-2">
                                                            <p><b>Red flag (if any)</b></p>
                                                            <p className="colorBlack">{this.state.red_flag}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );
        } else {
            return <Navigate to="/" />;
        }
    }
}

export default ReportsData;
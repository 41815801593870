import React from "react";

import english_banner from '../../assets/img/subjects/english_banner.png'
import { NavLink } from "react-router-dom";
import earth from '../../assets/img/subjects/earth.png'
import breather from '../../assets/img/subjects/breather.png'
import sort from '../../assets/img/subjects/sort.png'


class OurApproach extends React.Component {
    render() {
        return (
            <>
                <h1 className="text-center mt-3 mb-3" style={{ fontSize: 50 }}> Our Approach</h1>
                <div className=" pb-5">
                    <div className="row mt-3">
                        <div className="col-md-1"></div>
                        <div className="col-md-4" style={{ marginTop: 15 }}>
                            <p className="head_Text colorBlack " style={{ fontSize: 40, lineHeight: '50px', textAlign: 'left' }}>We priortize knowledge
                                instead of grades</p>
                            <p className="sub_text colorBlack mt-5" style={{ fontWeight: 600, textAlign: 'justify' }}>Education Capsule places knowledge above grades, emphasizing a holistic learning experience that encourages curiosity, critical thinking, and a deep understanding of subjects.
                            </p>

                            <div className="mt-3">
                                <NavLink className="mt-3 btn btn-info student-registration" to="/book-a-tutor" style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Book free Lesson</span></NavLink>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-5 mt-4">
                            <img src={english_banner} alt="english_banner" />
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div >

                <div className="mt-5 bg-black mission_bg" >
                    <p className="head_Text text-center pt-5 pb-5 mt-5" >Our Mission</p>
                    <div className="row" style={{ padding: 20 }}>
                        <div className="col-md-4 imgcenter" >
                            <div className="mission_div">
                                <p className="reviwesfont mt-1 mission_heading" >Make education<br></br>
                                    accessible to all</p>
                                <p className="colorBlack mt-1 block_font">Education Capsule is driven by the belief that education should be accessible to all, regardless of background or circumstance. We are committed to breaking down barriers and making quality education a universal right, not a privilege. Our approach includes affordable options, diverse resources, and innovative teaching methods to ensure that every learner can access the knowledge and support they need. We strive to level the educational playing field, empowering individuals to reach their full potential. By fostering inclusivity and providing equal opportunities, Education Capsule is dedicated to creating a brighter future for all learners, regardless of their starting point in life.</p>
                            </div>
                        </div>
                        <div className="col-md-4 imgcenter" >
                            <div className="mission_div widthauto">
                                <p className="reviwesfont mt-1 mission_heading" >Offering appropriate<br></br> guidance</p>
                                <p className="colorBlack mt-1 block_font" >We recognize that education is not just about the delivery of content but also about mentorship and support. Our team is committed to offering personalized guidance to help students navigate their educational journey effectively. Whether it's academic advice, career counselling, or personal development, we are here to empower our learners with the knowledge and guidance they need to make informed decisions and achieve their goals. Through this guidance, we aim to instil confidence and a sense of direction in our students, enabling them to make well-informed choices and excel in their educational and life pursuits. Our commitment to providing proper guidance is a cornerstone of our educational philosophy.</p>
                            </div>
                        </div>
                        <div className="col-md-4 imgcenter">
                            <div className="mission_div">
                                <p className="reviwesfont mt-1 mission_heading" >Continuous<br></br>Improvement</p>
                                <p className="colorBlack mt-1 block_font" >At Education Capsule, our commitment to continuous improvement is unwavering. We understand that education is an evolving journey, and to stay at the forefront, we embrace change. Our relentless pursuit of excellence entails constant refinement of our teaching methods, curriculum, and technology integration. We meticulously analyze student outcomes and integrate their feedback to enhance our courses continually. Our tutors receive ongoing professional development to remain updated with the latest educational practices. We believe that learning never stops, and neither does our mission to enhance the educational experience. We are committed to delivering the best possible education, driven by our belief in the power of continuous growth and learning.</p>
                            </div>
                        </div>
                    </div>

                    <div className="our_mission">
                        <p className="text-center pt-5 text_res" style={{ fontWeight: 700, fontSize: 38, color: '#fff' }}>Our Vision and Ambition</p>
                        <div className="row vision_div" style={{ marginLeft: '15%', marginRight: '15%' }}>
                            <div className="col-md-2"></div>
                            <div className="col-md-8 pb-5">
                                <div className="row mt-4">
                                    <div className="col-md-3">
                                        <img src={earth} />
                                    </div>
                                    <div className="col-md-9">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 22, }}>Our Mission</p>
                                        <p className="mt-1 widthauto" style={{ textAlign: 'justify', color: '#fff', width: 575 }}>Our Vision is to provide students with a personalised learning experience that is tailored to suit the different needs and learning styles of every student. Education Capsule is the best online tutoring platform which is dedicated to guiding and supporting students to excel in their studies and overcome their fear and anxiety of learning any subject and any topic that they are unable to understand and need a deep understanding of the same.</p>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3 mt-3">
                                        <img src={breather} />
                                    </div>
                                    <div className="col-md-9">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 22, }}>Our goal</p>
                                        <p className="mt-1 widthauto" style={{ textAlign: 'justify', color: '#fff', width: 575 }}>Our primary goal is to enhance students’ academic performance while also offering convenient learning experiences to students that allow them to access tutoring sessions from the comfort of their homes. We ensure that the overall knowledge and conceptual development of the Child is prioritized for which we provide the best online tutoring services for major subjects such as Maths, Science, Computing, and English across the world and at convenient times for the students.</p>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-3 mt-4">
                                        <img src={sort} />
                                    </div>
                                    <div className="col-md-9">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 22, }}>We offer</p>
                                        <p className="mt-1 widthauto" style={{ textAlign: 'justify', color: '#fff', width: 575 }}>Online tutoring services offer a wide range of benefits and resources to students that can help them enhance their learning experience and achieve their academic goals. We offer Personalised and regular one-on-one classes for the students for different subjects such as Maths, Science, English, and Computers. Along with this we also help students to prepare for competitive exams for all the subjects so that students can excel in their SATs and other mid-term exams.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </div >

            </>
        )
    }
}

export default OurApproach
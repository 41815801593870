import React, { useState } from "react";
import LoadingOverlay from 'react-loading-overlay';
import * as Constant from './Constant'

function ResetPassword() {


    const [loading, isLoading] = useState(false)
    const [password, setPassword] = useState()
    const [c_password, setC_Password] = useState()
    const [passwordError, setPasswordError] = useState(null)
    const [c_passwordError, setC_PasswordError] = useState(null)

    const onClick = async () => {
        isLoading(true)

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        const formData = new FormData();
        formData.append("password", password);
        formData.append("token", token);

        await fetch(Constant.resetPassword, {
            method: 'POST',
            body: formData
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.message) {
                    isLoading(false)
                    window.location.href = Constant.BaseUrl + 'login'
                }
                else {
                    console.log('Password Error')
                }
            })
    }

    const validate = () => {
        let passwordError = '';
        let c_passwordError = '';


        var pwd = password;
        var regPwd = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        );

        if (!password) {
            passwordError = "The Password cannot be empty";
        } else {
            if (pwd.length < 8) {
                passwordError = "Password cannot be less than eight characters";
            } else {
                if (!regPwd.test(pwd)) {
                    passwordError =
                        "Please enter a valid password, and it should contain at least one uppercase letter, one lowercase letter, one number and one special character. e.g. (Welcome@1)";
                }
            }
        }


        if (!c_password) {
            c_passwordError = "Please Enter Confirm Password"
        } else {
            if (password !== c_password) {
                c_passwordError = "The Password does not match. Try again";
            }
        }

        if (passwordError || c_passwordError) {
            setPasswordError(passwordError)
            setC_PasswordError(c_passwordError)

            return false
        }

        return true;

    }

    const onSubmit = () => {
        if (validate()) {
            onClick();
        }
    }



    return (
        <>
            <LoadingOverlay
                active={loading}
                spinner
                fadeSpeed={10}
                text='Please wait...'
            >
                <section id="loginnn">
                    <div class="container">
                        <div class="registration-form-outer loginnnn">
                            <div class="container register">
                                <div class="row">
                                    <div class="col-md-8 register-right" alt="Education Capsule">
                                        <div class="tab-content" id="myTabContent">
                                            <h2 className="text-center mt-4">Reset Password</h2>
                                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div class="row register-form">

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>Password</h5>
                                                            <input type="password" autoComplete="on" id="password" className="form-control" name="password" placeholder="Password *" onChange={(e) => setPassword(e.target.value)} />
                                                        </div>
                                                        {!!passwordError && (
                                                            <text style={{ color: "red", marginLeft: "10px" }}>
                                                                {passwordError}
                                                            </text>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>Confirm Password</h5>
                                                            <input type="password" autoComplete="on" className="form-control" name="c_password" id="c_password" placeholder="Confirm Password *" onChange={(e) => setC_Password(e.target.value)} />
                                                        </div>
                                                        {!!c_passwordError && (
                                                            <text style={{ color: "red", marginLeft: "10px" }}>
                                                                {c_passwordError}
                                                            </text>
                                                        )}
                                                    </div>

                                                    <div class="col-md-6 m-auto ">
                                                        <div class="form-group ">
                                                            <input type="submit" class="btnRegister" onClick={() => onSubmit()} value="Submit" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </LoadingOverlay>
        </>
    )
}

export default ResetPassword;
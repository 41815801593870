import React from "react";

import examPrep from '../../assets/img/newhomepage/examPrep.png'

import icon_2 from '../../assets/img/newhomepage/Icon_2.png'
import icon_1 from '../../assets/img/newhomepage/icon_6.png'
import icon_3 from '../../assets/img/newhomepage/icon_7.png'
import icon_4 from '../../assets/img/newhomepage/icon_8.png'
import icon_5 from '../../assets/img/newhomepage/icon_9.png'
import icon_6 from '../../assets/img/newhomepage/icon_10.png'

import exam_prep from '../../assets/img/services/new/exam_pre.png'
import * as Constant from '../Constant'

class ExamPreparation extends React.Component {

    constructor() {
        super()

        this.state = {
            paperList: [],
            isLoading: false
        }
    }

    getPapers = async () => {
        this.setState({ isLoading: true })


        await fetch(Constant.getPapers + '/' + 1, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                this.setState({ paperList: data.data, isLoading: false })
            })
    }

    componentDidMount = () => {
        this.getPapers()
    }

    showPaper = (id, totalQuestion) => {
        if (totalQuestion === 0) {
            const url = Constant.BaseUrl + 'contact-us'
            window.location.href = url;
        } else {
            const url = Constant.BaseUrl + 'exam_papers/1/' + id;
            // window.open(url, '_blank');
            window.location.href = url;
        }

    }


    render() {
        return (
            <>
                <div style={{ marginTop: '-1px' }}>
                    <section className="service-page-new" >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 col-sm-12 col-lg-7 col-xl-7 col-xs-12">
                                    <h1 className="banner-text1" style={{ textAlign: 'left', marginTop: 50 }}>We are here for the<br></br> best grades a child needs!</h1>
                                    <p style={{ color: '#fff', textAlign: 'justify' }}>Education Capsule offers exam preparation help for 7+, 11+, 13+, SATs and GCSE. The education capsule provides sample papers that cover the overall syllabus for Maths, Science, English & IT. The sample papers are designed in such a way that students excel in 7+, 11+, 13+ & SAT exams.
                                    </p>
                                    <a href="/book-a-tutor" className="lending-btn" >Book free Lesson Now</a>

                                </div>
                                <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12">
                                    <img src={exam_prep} className="p-2" />
                                </div>
                            </div>
                        </div>
                    </section >
                    <section style={{ marginTop: '-265px' }}>
                        <div className="container">
                            <div className="col-xl-12">
                                <h2 className="largefont">Exam Preparation</h2>
                                <p className="subheading">Education Capsule provides experienced tutors, practice multi-level quizzes, and <br></br> doubt-clearing sessions to help students excel.</p>
                            </div>
                            <div className="row " >
                                {
                                    this.state.paperList.map((item, i) => (
                                        <div className="col-xl-4 col-md-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div className="cardnew" key={i} style={{ cursor: 'pointer', height: 'auto' }} onClick={() => this.showPaper(item.id, item.total_question)}>
                                                <div className="imagecard">
                                                    <img src={examPrep} alt="seven" className="sliderimage" />
                                                </div>
                                                <div className="card-bodynew">
                                                    <h5 className="card-title">{item.name}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {/* <div className="col-xl-3 col-md-3">
                                </div>
                                <div className="col-xl-3 col-md-3">
                                    <div className="cardnew">
                                        <div className="imagecard">
                                            <img src={seven} alt="seven" className="sliderimage" />
                                        </div>
                                        <div className="card-bodynew">
                                            <h5 className="card-title">7+ Exam</h5>
                                            <p className="card-textnew mt-4" style={{ fontSize: '15px', textAlign: 'center' }}>At the end of year 2, students appear for the 7+ examination for entry to year 3 into top private schools across the country.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-3">
                                    <div className="cardnew">
                                        <div className="imagecard">
                                            <img src={eleven} alt="eleven" className="sliderimage" />
                                        </div>
                                        <div className="card-bodynew">
                                            <h5 className="card-title">11+ Exam</h5>
                                            <p className="card-textnew mt-4" style={{ fontSize: '15px', textAlign: 'center' }}>At the end of year 6, the students appear for the 11+ examination, which is an exam that is used to check or estimate the pupil’s level for entry into year 7.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-3">
                                    <div className="cardnew">
                                        <div className="imagecard">
                                            <img src={thirteen} alt="thirteen" className="sliderimage" />
                                        </div>
                                        <div className="card-bodynew">
                                            <h5 className="card-title">13+ Exam</h5>
                                            <p className="card-textnew mt-4" style={{ fontSize: '15px', textAlign: 'center' }}>At the end of year 8, the students appear for the 13+ examination, which is the exam used for students to enter year 9 in secondary school.
                                            </p>
                                        </div>
                                    </div>
                                </div> */}
                        </div>
                    </section>

                    <div className="container mt-2">
                        <div className="col-xl-12 ">
                            <h2 className="largefont">Why Choose Education Capsule<br></br> For Exam Prep?
                            </h2>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 my-5  mobileclass" >
                                <div className="row mt-5 format">
                                    <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12 firstdiv" >
                                        <img src={icon_1} alt="video" style={{ height: '58px' }} />
                                    </div>
                                    <div className="col-md-9 col-lg-9 col-xl-9 col-sm-12 col-xs-12 seconddiv"><p className="textdiv" style={{ color: '#3989B8', cursor: 'pointer' }}>Reliable Tutors</p></div>
                                </div>
                                <div className="row mt-4 format">
                                    <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12 firstdiv" >
                                        <img src={icon_2} alt="video" style={{ height: '30px' }} />
                                    </div>
                                    <div className="col-md-9 col-lg-9 col-xl-9 col-sm-12 col-xs-12 seconddiv"><p className="textdiv" style={{ color: '#3989B8', cursor: 'pointer' }}>Tailor-made Classes</p></div>
                                </div>
                                <div className="row mt-4 format">
                                    <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12 firstdiv" >
                                        <img src={icon_3} alt="video" style={{ height: '55px' }} />
                                    </div>
                                    <div className="col-md-9 col-lg-9 col-xl-9 col-sm-12 col-xs-12 seconddiv"><p className="textdiv" style={{ color: '#3989B8', cursor: 'pointer' }}>Practice Papers
                                    </p></div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 my-5 mobileclass" >
                                <div className="row mt-5 format">
                                    <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12 firstdiv" >
                                        <img src={icon_4} alt="video" style={{ height: '50px' }} />
                                    </div>
                                    <div className="col-md-9 col-lg-9 col-xl-9 col-sm-12 col-xs-12 seconddiv"><p className="textdiv" style={{ color: '#3989B8', cursor: 'pointer' }}>Doubt Clearing Session</p></div>
                                </div>
                                <div className="row mt-4 format">
                                    <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12 firstdiv" >
                                        <img src={icon_5} alt="video" style={{ height: '50px' }} />
                                    </div>
                                    <div className="col-md-9 col-lg-9 col-xl-9 col-sm-12 col-xs-12 seconddiv"><p className="textdiv" style={{ color: '#3989B8', cursor: 'pointer' }}>Multi-level Quiz</p></div>
                                </div>
                                <div className="row mt-4 format">
                                    <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12 firstdiv" >
                                        <img src={icon_6} alt="video" style={{ height: '60px' }} />
                                    </div>
                                    <div className="col-md-9 col-lg-9 col-xl-9 col-sm-12 col-xs-12 seconddiv"><p className="textdiv" style={{ color: '#3989B8', cursor: 'pointer' }}>Round-the-clock availability</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>


        );
    }
}

export default ExamPreparation

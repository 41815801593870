import React from 'react';

const VideoEmbed = () => {
  return (
    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 448,width:'93%', overflow: 'hidden', background: '#000' }}>
      <iframe
        src="https://www.youtube.com/embed/oGEmysdbs2k"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 448 }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video"
      ></iframe>

    </div>
  );
};

export default VideoEmbed;

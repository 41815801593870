import React from "react";

import model from '../assets/img/services/model.png'

export class WhistleBlowingPolicy extends React.Component {
  render() {
    return (
      <>
        <section id="examhelp-sec-6">
          <div className="container">
            <h1 className="text-center">Whistleblowing policy</h1>
            <div className="outer">
              <div className="row">

                <div className="col-12">
                  <div className="homework-data">
                    <p>Education Capsule trusts in performing its duties in an honest and fair manner.  We believe in adopting the utmost standards of professionalism, integrity, and ethical behavior. All of our processes are transparent. The CEO of our company has adopted the Whistle Blower Policy to ensure the employees of our company have the freedom and a secure way to report any major/minor concerns they may have of genuine, suspected or planned misconduct involving our company or any of its directors, managers or employees. <br></br>
                      This policy applies to everyone established with Education Capsule. We have a vigil mechanism for directors and employees to report any concern about unethical behavior, actual or suspected fraud, or any violation of

                      Education Capsule and its code of conduct. Through this policy, employees have a right to report any concerns. Our employees fully understand that he/she won’t be subject to any recrimination or discrimination after any act of whistle blowing on his/her part. Reports of such concerns are very important for us and we take them very seriously. Everyone associated with Education Capsule is obliged to comply with the company’s policies and report any violation of the same using the internal reporting mechanisms.</p>
                  </div>
                </div>

                <div className="col-lg-6 col-12 col-md-6">
                  <div className="icon-outer-2">
                    <img src={model} alt="" />
                  </div>
                </div>

                <div className="col-lg-6 col-12 col-md-6">
                  <div className="homework-data-4">
                    Contact Details for Safeguarding<br></br>
                    Our Designated Safeguarding Lead can be contacted at<br></br>
                    <a href="tel:+442476981444" style={{ color: '#000' }}> +442476981444 </a><br></br>
                    <a href="mailto:admin@educationcapsule.com" style={{ color: '#000' }}> admin@educationcapsule.com</a>
                  </div>

                </div>

              </div>

            </div>

          </div>
        </section>
      </>

    )
  }
}

export default WhistleBlowingPolicy
import React, { useState } from 'react'
import { Link, useParams } from "react-router-dom";
import * as Constant from '../Constant'
import { useEffect } from 'react';
import banner from '../../assets/img/exam_banner.png'

export default function ExamPaper() {
    const { sid, pid } = useParams();
    const [examPaperList, setExamPaperList] = useState([]);
    const [paperName, setPaperName] = useState();
    const [sub, setSub] = useState();
    const [subject, setSubject] = useState();

    const [state, setState] = useState({
        tabone: true,
        tabtwo: false,
        tabthree: false,
        tabfour: false,
        tabfive: false,
    })

    useEffect(() => {
        getExamPaper();

    }, [])

    const getExamPaper = async () => {

        await fetch(Constant.getExamPapers + '/' + sid + '/' + pid, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {

                    setPaperName(data.data[0].paperName)
                    setSubject(data.data[0].SubjectName)
                    setExamPaperList(data.data)

                    const regex = /\d+\+/;
                    var inputString = data.data[0].paperName;
                    const result = inputString.match(regex);
                    const extractedText = result ? result[0] : null;
                    setSub(extractedText)
                }
            })
    }

    const ShowTab = (value) => {
        if (value === 'tabone') {
            setState({ ...state, tabone: true, tabthree: false, tabtwo: false, tabfour: false, tabfive: false })
        } else if (value === 'tabtwo') {
            setState({ ...state, tabone: false, tabtwo: true, tabthree: false, tabfour: false, tabfive: false })
        } else if (value === 'tabthree') {
            setState({ ...state, tabone: false, tabtwo: false, tabthree: true, tabfour: false, tabfive: false })
        } else if (value === 'tabfour') {
            setState({ ...state, tabone: false, tabtwo: false, tabthree: false, tabfour: true, tabfive: false })
        } else {
            setState({ ...state, tabone: false, tabtwo: false, tabthree: false, tabfour: false, tabfive: true })
        }
    }

    const showPaper = (id) => {
        // const url = Constant.BaseUrl + 'question_papers/' + id;
        // window.open(url, '_blank');
        const url = Constant.BaseUrl + 'question_papers/' + id;
        window.open(url, '_blank');
    }

    const userId = localStorage.getItem('userId');

    const planId = localStorage.getItem('planId');

    const getPremium = () => {
        const userId = localStorage.getItem('userId');

        if (userId === null) {
            var currentUrlPath = window.location.pathname;
            var pathSegments = currentUrlPath.split('/').slice(1);
            var formattedPath = pathSegments.join('/');
            localStorage.setItem('currentPath', formattedPath)
            window.location.href = Constant.BaseUrl + 'login'
        } else {
            window.location.href = Constant.BaseUrl + 'membership'
        }
    }





    return (
        <>
            <div className='row'>
                <div className='col-md-1'></div>
                <div className='col-md-10 pb-5 pt-5 mb-3 bg-black' style={{ border: 'none', borderBottomLeftRadius: 30, borderBottomRightRadius: 30 }}>
                    <p className='text-center' style={{ color: '#fff', fontSize: 40, fontWeight: 600 }}> {paperName}</p>
                </div>
                <div className='col-md-1'></div>

            </div>
            <div className='row mt-5 mb-4'>
                <div className='col-md-1'></div>
                <div className='col-md-5'>
                    <p className='mt-5 mb-4' style={{ color: '#000', fontSize: 43, fontWeight: 700 }}> {paperName}</p>
                    <p className='mt-5' style={{ color: '#000', fontSize: 27, fontWeight: 700 }}>Unlock Success with the Best {sub} Practice Papers!</p>
                    <p className='mt-5' style={{ color: '#000', fontSize: 20, fontWeight: 700, textAlign: 'justify' }}>Welcome to Education Capsule, your trusted source for {paperName}.We understand the importance of early education and offer a wide range of sample papers designed to help your child excel in their admissions journey.Our meticulously crafted practice materials cover all the important topics of Mathematics to ensure your child's readiness.With our user-friendly platform, you can access and practice these papers conveniently.Give your child the edge they need to succeed in {sub} Exam!</p>
                </div>
                <div className='col-md-1'></div>
                <div className='col-md-5'>
                    <img src={banner} />
                </div>
            </div>
            <div className="mt-5 bg-black pb-5 pt-5" style={{ border: 'none' }}>
                <div className="row">
                    <div className='col-md-1'></div>
                    <div className="col-md-3">
                        <div className="reviwesfont1 text-center">200+</div>
                        <div className="reviwesfont2 text-center">Solved Exam papers</div>
                    </div>

                    <div className="col-md-4 ">
                        <div className="reviwesfont1 text-center" style={{ marginLeft: 0 }}>8000+</div>
                        <div className="reviwesfont2 text-center" style={{ marginLeft: 0 }}>Topicwise Questions</div>
                    </div>

                    <div className='col-md-3' style={{ display: 'flex', alignItems: 'center' }}>
                        {
                            planId ? '' :
                                <button
                                    type="submit"
                                    name="submit"
                                    className="lending-btn demo-btn premium_btn"
                                    title="Get Premium !!"
                                    onClick={getPremium}
                                >
                                    Get premium
                                </button>}
                    </div>
                    <div className='col-md-1'></div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-1'></div>
                <div className='col-md-10'>
                    <div className="mt-5 bg-black pb-2 pt-2" style={{ border: 'none' }}>
                        <p className='text-center mt-4 mb-4' style={{ color: '#fff', fontSize: 35, fontWeight: 600 }}> Best {sub} Entrance Exam Practice Papers for 2024 exams!</p>
                    </div>
                    {
                        userId === null ? <>
                            {
                                examPaperList.map((item, i) => (
                                    <>
                                        {
                                            i < 5 ? <>
                                                <div className='row mt-3' key={i}>
                                                    <div className='col-md-8'>
                                                        <p className='mt-2 mb-2 text-center' style={{ color: '#000', fontSize: 25, fontWeight: 600 }}>{item.paper_title}</p>
                                                    </div>
                                                    <div className='col-md-2'><a type="submit" className="faqbutn que_ansbtn past_exam" href='#' onClick={() => showPaper(item.id)}>Questions</a></div>
                                                    {
                                                        item.answer_sheet === null ? '' : <div className='col-md-2'><a type="submit" className="faqbutn que_ansbtn past_exam" href={"https://admin.educationcapsule.com/public/assets/answersheet/" + item.answer_sheet} target='_blank' download>Answer</a></div>
                                                    }
                                                </div>
                                                <hr></hr></> : ''
                                        }

                                    </>
                                ))
                            }</> :
                            <> {
                                examPaperList.map((item, i) => (
                                    <>
                                        {
                                            planId ? <> <div className='row mt-3' key={i}>
                                                <div className='col-md-8'>
                                                    <p className='mt-2 mb-2 text-center' style={{ color: '#000', fontSize: 25, fontWeight: 600 }}>{item.paper_title}</p>
                                                </div>
                                                <div className='col-md-2'><a type="submit" className="faqbutn que_ansbtn past_exam" href='#' onClick={() => showPaper(item.id)}>Questions</a></div>
                                                {
                                                    item.answer_sheet === null ? '' : <div className='col-md-2'><a type="submit" className="faqbutn que_ansbtn past_exam" href={"https://admin.educationcapsule.com/public/assets/answersheet/" + item.answer_sheet} target='_blank' download>Answer</a></div>
                                                }
                                            </div>
                                                <hr></hr>
                                            </> :
                                                <>
                                                    {
                                                        i < 5 ? <>
                                                            <div className='row mt-3' key={i}>
                                                                <div className='col-md-8'>
                                                                    <p className='mt-2 mb-2 text-center' style={{ color: '#000', fontSize: 25, fontWeight: 600 }}>{item.paper_title}</p>
                                                                </div>
                                                                <div className='col-md-2'><a type="submit" className="faqbutn que_ansbtn past_exam" href='#' onClick={() => showPaper(item.id)}>Questions</a></div>
                                                                {
                                                                    item.answer_sheet === null ? '' : <div className='col-md-2'><a type="submit" className="faqbutn que_ansbtn past_exam" href={"https://admin.educationcapsule.com/public/assets/answersheet/" + item.answer_sheet} target='_blank' download>Answer</a></div>
                                                                }
                                                            </div>
                                                            <hr></hr></> : ''
                                                    }
                                                </>
                                        }
                                    </>
                                ))
                            }</>
                    }

                    <div className="mt-2 bg-black pb-2 pt-2" style={{ border: 'none' }}>
                        <div className='row'>
                            <div className='col-md-8'>
                                <p className='text-center mt-4 mb-4' style={{ color: '#fff', fontSize: 32, fontWeight: 600 }}>Upgrade to premium to download detailed solutions</p>
                            </div>
                            <div className='col-md-4' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                {
                                    planId ? '' : <button
                                        type="submit"
                                        name="submit"
                                        className="lending-btn demo-btn premium_btn mt-3 mb-3"
                                        title="Get Premium !!"
                                        onClick={getPremium}
                                        style={{ height: 'auto', width: 'auto', fontSize: 22, width: 200 }}
                                    >
                                        Get premium
                                    </button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-1'></div>

                {/* <div className='mt-3'>
                    <div className='col-md-2' style={{ marginLeft: 'auto', marginRight: 'auto' }}><a type="submit" className="faqbutn que_ansbtn " href='#'>Load More</a></div>
                </div> */}
            </div>


            <div className="faq_sec mt-4 mb-5 pb-5" style={{ marginLeft: '8%', marginRight: '8%' }}>
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6">
                        <h1 className="mt-3 ms-5 FAQS">FAQs.</h1>
                        <div className="mt-4 mb-5">
                            <div className={state.tabone === true ? "faq_question focustab" : "faq_question"} data-toggle="tab" onClick={() => ShowTab('tabone')} role="tab">What Services does Education Capsule provide?</div>
                            <div className={state.tabtwo === true ? "faq_question focustab mt-3" : "faq_question mt-3"} data-toggle="tab" onClick={() => ShowTab('tabtwo')} role="tab">How do I get started?</div>
                            <div className={state.tabthree === true ? "faq_question focustab mt-3" : "faq_question mt-3"} data-toggle="tab" onClick={() => ShowTab('tabthree')} role="tab">What subjects do you offer tutoring in?</div>
                            <div className={state.tabfour === true ? "faq_question focustab mt-3" : "faq_question mt-3"} data-toggle="tab" onClick={() => ShowTab('tabfour')} role="tab">How does Education Capsule work?</div>
                            <div className={state.tabfive === true ? "faq_question focustab mt-3" : "faq_question mt-3"} data-toggle="tab" onClick={() => ShowTab('tabfive')} role="tab">Can you accommodate flexible tutoring schedules?</div>
                        </div>

                    </div>
                    <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12 col-xl-5">
                        <h1 className="mt-3 ms-5 FAQS" style={{ marginLeft: "6%" }}>Answers</h1>
                        <div className="mt-4 mb-5" style={{ height: '75%' }} >
                            {state.tabone === true ?
                                <div className="faq_anss">
                                    <p className="pragraftab mt-3">
                                        Education Capsule is an online tutoring platform with qualified tutors. We
                                        provide one-to-one personalized tutors from the school level to the university level with
                                        conceptual clarity over various subjects. We also help students to prepare for SATs,
                                        GCSE, 11+, 13+, A level etc.
                                    </p>
                                    <Link type="submit" className="faqbutn mt-5 text-center" to='contact-us'>Know more</Link>
                                </div>
                                : ''}

                            {state.tabtwo === true ?
                                <div className="faq_anss">
                                    <p className="pragraftab mt-3">
                                        Sign up using the ‘Join Now’ button and submit the ‘Book Lesson Now’ Form
                                        to book your free demo lesson with Education Capsule. Once done with the demo
                                        lesson, you can connect via https://educationcapsule.com/contact-us to proceed
                                        further.
                                    </p>
                                    <Link type="submit" className="faqbutn mt-5 text-center" to='contact-us'>Know more</Link>
                                </div>
                                : ''}

                            {state.tabthree === true ?
                                <div className="faq_anss">
                                    <p className="pragraftab mt-3">
                                        We provide tutoring services for a wide range of subjects, with the most
                                        popular ones being Mathematics, English, Test Preparation, Science, English as a
                                        Second Language (ESL), and many others.
                                    </p>
                                    <Link type="submit" className="faqbutn mt-5 text-center" to='contact-us'>Know more</Link>
                                </div>
                                : ''}
                            {state.tabfour === true ?
                                <div className="faq_anss">
                                    <p className="pragraftab mt-3">
                                        Upon booking live sessions with us, we begin with a pre-assessment to gauge
                                        the student's needs, and then tailor lessons accordingly. Additionally, we offer multi-
                                        level quizzes for practice.
                                    </p>
                                    <Link type="submit" className="faqbutn mt-5 text-center" to='contact-us'>Know more</Link>
                                </div>
                                : ''}
                            {state.tabfive === true ?
                                <div className="faq_anss">
                                    <p className="pragraftab mt-3">
                                        Education capsule is available around the clock, ensuring flexibility to meet your
                                        learning needs at any time. Whether it's day or night, we're here to support your
                                        education journey. Tutors are available to assist you whenever you require their
                                        expertise.
                                    </p>
                                    <Link type="submit" className="faqbutn mt-5 text-center" to='contact-us'>Know more</Link>
                                </div>
                                : ''}

                        </div>
                    </div>
                    <div className="col-md-1 col-lg-1 col-sm-12 col-xs-12 col-xl-1"></div>
                </div>
            </div>
        </>
    )
}
